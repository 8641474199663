import * as React from 'react';

const LinkedinIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="currentColor" />
    <rect x="8" y="8" width="20" height="20" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0717 15.7268H18.8572V17.1143C19.2585 16.3163 20.2875 15.5993 21.8333 15.5993C24.7965 15.5993 25.5 17.1878 25.5 20.1023V25.5H22.5V20.766C22.5 19.1062 22.0988 18.1703 21.0773 18.1703C19.6605 18.1703 19.0717 19.179 19.0717 20.7653V25.5H16.0717V15.7268V15.7268ZM10.9275 25.3725H13.9275V15.5993H10.9275V25.3725V25.3725ZM14.3573 12.4125C14.3574 12.664 14.3075 12.9129 14.2105 13.1449C14.1136 13.377 13.9715 13.5874 13.7925 13.764C13.4298 14.1245 12.9389 14.3262 12.4275 14.325C11.917 14.3247 11.4272 14.1234 11.064 13.7648C10.8857 13.5875 10.744 13.3769 10.6473 13.1448C10.5505 12.9128 10.5004 12.6639 10.5 12.4125C10.5 11.9048 10.7025 11.4188 11.0648 11.0603C11.4277 10.7011 11.9177 10.4998 12.4283 10.5C12.9398 10.5 13.4302 10.7018 13.7925 11.0603C14.154 11.4188 14.3573 11.9048 14.3573 12.4125Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedinIcon;
