import * as React from 'react';

const HomeIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.5L9.8 1L18.6 6.5M16.6444 10V16.4C16.6444 16.5591 16.5826 16.7117 16.4726 16.8243C16.3626 16.9368 16.2134 17 16.0578 17H3.54222C3.38663 17 3.23741 16.9368 3.12739 16.8243C3.01736 16.7117 2.95556 16.5591 2.95556 16.4V10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
