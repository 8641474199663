import {
  CardHeader,
  Card as MuiCard,
  CardActions,
  Typography,
  CardMedia,
  Theme,
  CardActionArea,
  CardContent,
  Divider,
} from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { Box } from '@mui/system';
import { SxProps } from '@mui/material';
import Link from '../link/link';
import { useAmplitude } from 'react-amplitude-hooks';
import { AnalyticsEvent } from 'utility/analytics/events';

export interface ICard {
  header?: {
    title?: string;
    centerTitle?: boolean;
    subHeader?: string | ReactNode;
    shortDescription?: string;
    subHeader2?: string | ReactNode;
    badge?: { element?: ReactNode; status: ReactNode };
    href?: string;
  };
  content?: { content: ReactNode; showDivider?: boolean };
  avatar?: ReactNode;
  actions?: ReactNode[] | ReactNode;
  elementKey: string | number;
  cardMedia?: {
    image?: string;
    alt: string;
    size: 'medium' | 'large';
  };
  sx?: SxProps<Theme>;
  onMouseEnterActionArea?: () => void;
  onClick?: () => void;
  showContent?: boolean;
  href?: string;
  cardType: 'claim' | 'offer' | 'cms' | 'offer' | 'policy' | 'benefit';
  logObject?: object;
}

const Card = ({
  header,
  content,
  avatar,
  actions,
  elementKey: key,
  cardMedia,
  sx,
  onMouseEnterActionArea,
  onClick,
  showContent = true,
  href,
  cardType,
  logObject = {},
}: ICard): JSX.Element => {
  const { logEvent } = useAmplitude();
  const logClick = () => {
    logEvent(`${cardType} ${AnalyticsEvent.CARD_CLICK}`, {
      key: key,
      href: href,
      cardType: cardType,
      ...logObject,
    });
  };
  let cardContent = (
    <>
      {cardMedia && (
        <CardMedia
          component="img"
          sx={{
            height: { xs: 137, md: cardMedia.size === 'large' ? 210 : 137 },
          }}
          image={cardMedia.image}
          alt={cardMedia.alt}
        />
      )}
      {header && (
        <>
          {header.badge && (
            <Box sx={{ position: href ? '' : 'relative' }}>
              {header.badge.element}
              <Box
                sx={{
                  top: 0,
                  right: 0,
                  position: 'absolute',
                  mr: 1,
                  mt: 1,
                  maxWidth: 'calc(100% - 90px);',
                  textAlign: 'right',
                }}
              >
                <div className="u">{header.badge.status}</div>
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex' }}>
            <CardHeader
              title={
                <Typography
                  noWrap
                  sx={{ textAlign: header.centerTitle ? 'center' : 'none' }}
                  className="u"
                  variant={showContent ? 'h6' : 'subtitle1'}
                >
                  {header.title}
                </Typography>
              }
              subheader={
                <Box>
                  <Typography noWrap variant="body2">
                    {header.subHeader}
                  </Typography>
                  {header.subHeader2 && (
                    <Typography noWrap variant="body2">
                      {header.subHeader2}
                    </Typography>
                  )}
                </Box>
              }
              avatar={avatar}
              sx={{
                '.MuiCardHeader-content': { maxWidth: '100%' },
                display: 'flex',
                width: '100%',
                pb: 1,
                borderRadius: showContent ? '12px 12px 0 0' : '',
                mt: showContent ? '-10px' : 0,
                backgroundColor: '#fff',
              }}
            />
          </Box>
        </>
      )}
      {header && header.shortDescription && (
        <Typography
          sx={{ textAlign: 'center', my: 1, px: 1.5 }}
          variant="body2"
        >
          {header.shortDescription}
        </Typography>
      )}

      {showContent && content && (
        <CardContent
          sx={{
            py: 0,
            '&:last-child': {
              py: 0,
            },
          }}
        >
          {content.showDivider && <Divider sx={{ my: 1 }} />}
          {content.content}
        </CardContent>
      )}
      {actions && (
        <CardActions sx={{ pr: 2, float: 'right' }}>{actions}</CardActions>
      )}
    </>
  );

  if (href) {
    cardContent = (
      <CardActionArea
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'stretch',
          pb: 1,
          position: 'relative',
        }}
        onMouseEnter={
          onMouseEnterActionArea ? onMouseEnterActionArea : undefined
        }
      >
        <Link
          underline="none"
          onClick={() => logClick()}
          href={href}
          sx={{ color: 'text.primary' }}
        >
          {cardContent}
        </Link>
      </CardActionArea>
    );
  }

  if (onClick) {
    cardContent = (
      <CardActionArea
        sx={{}}
        onMouseEnter={
          onMouseEnterActionArea ? onMouseEnterActionArea : undefined
        }
        onClick={() => {
          onClick();
          logClick();
        }}
      >
        {cardContent}
      </CardActionArea>
    );
  }

  const clickable = onClick || href;
  return (
    <MuiCard
      className={cardType === 'cms' ? 'u' : undefined}
      variant="elevation"
      sx={{ pb: clickable ? 0 : 1, position: 'relative', ...sx }}
      key={key}
    >
      {cardContent}
    </MuiCard>
  );
};

export default Card;
