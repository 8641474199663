export class CustomError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  public status?: number;
  public info?: string;
}
