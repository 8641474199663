import { CmsProductForSale } from 'utility/node/sanity/sanity';
import { randomString } from 'utility/string';
import { createStore } from 'zustand';

export interface BuyInsuranceProduct {
  buyInsuranceId: string;
  productSelected: boolean;
  cmsProductForSale: CmsProductForSale;
  shortDescription?: string;
  objectFields?: { [key: string]: string };
  recommendation?: {
    recommended: boolean;
    modelId: string;
    requestID: string;
    feedbackProvided: boolean;
  };
}

export interface BuyInsuranceAnswers {
  productSelected: boolean;
}

export interface BuyInsuranceProps {
  productsInBuyInsurance: BuyInsuranceProduct[];
}
export interface BuyInsuranceInitialProps extends BuyInsuranceProps {
  productsInBuyInsurance: BuyInsuranceProduct[];
  recommendedProducts: CmsProductForSale[];
}

export type BuyInsuranceStore = ReturnType<typeof createBuyInsuranceStore>;

export interface ProductForSales extends CmsProductForSale {
  modelId: string;
  requestId: string;
  objectFields?: { [key: string]: string };
  shortDescription?: string;
}

export interface BuyInsuranceState extends BuyInsuranceProps {
  addRecommendedProducts: (product: ProductForSales[]) => void;
  addProductToBuyInsurance: (product: CmsProductForSale) => void;
  addProductsToBuyInsurance: (products: CmsProductForSale[]) => void;
  removeProductFromBuyInsurance: (buyInsuranceId: string) => void;
  deselectProduct: (buyInsuranceId: string) => void;
  toggleProductSelection: (buyInsuranceId: string) => void;
  isProductSelected: (buyInsuranceId: string) => boolean;
  clearState: () => void;
}

export const createBuyInsuranceStore = (
  initialProps?: Partial<BuyInsuranceInitialProps>
) => {
  if (initialProps.recommendedProducts) {
    initialProps.recommendedProducts.forEach((product) => {
      if (
        !initialProps.productsInBuyInsurance.find(
          (p) => p.cmsProductForSale.productLineId === product.productLineId
        )
      ) {
        initialProps.productsInBuyInsurance.push({
          cmsProductForSale: product,
          buyInsuranceId: randomString(),
          productSelected: false,
        });
      }
    });
  }

  const defaultProps: BuyInsuranceProps = {
    productsInBuyInsurance: [],
  };
  return createStore<BuyInsuranceState>((set, get) => ({
    ...defaultProps,
    ...initialProps,
    clearState: () => {
      set(defaultProps);
    },
    addProductToBuyInsurance: (product: ProductForSales) => {
      set((state) => ({
        productsInBuyInsurance: [
          ...state.productsInBuyInsurance,
          {
            cmsProductForSale: { ...product, recommended: false },
            buyInsuranceId: randomString(),
            productSelected: true,
            recommended: false,
            objectFields: product.objectData,
          },
        ],
      }));
    },
    addProductsToBuyInsurance: (products: ProductForSales[]) => {
      set((state) => ({
        productsInBuyInsurance: [
          ...state.productsInBuyInsurance,
          ...products.map<BuyInsuranceProduct>((p) => ({
            cmsProductForSale: { ...p, recommended: false },
            buyInsuranceId: randomString(),
            selected: true,
            productSelected: true,
            recommended: false,
            objectFields: p.objectData,
          })),
        ],
      }));
    },
    removeProductFromBuyInsurance: (buyInsuranceId: string) => {
      set((state) => ({
        productsInBuyInsurance: state.productsInBuyInsurance.filter(
          (p) => p.buyInsuranceId !== buyInsuranceId
        ),
      }));
    },
    deselectProduct: (buyInsuranceId: string) => {
      set((state) => ({
        productsInBuyInsurance: state.productsInBuyInsurance.map((p) =>
          p.buyInsuranceId === buyInsuranceId
            ? { ...p, productSelected: false }
            : p
        ),
      }));
    },
    toggleProductSelection: (buyInsuranceId: string) => {
      set((state) => {
        return {
          productsInBuyInsurance: state.productsInBuyInsurance.map((p) =>
            p.buyInsuranceId === buyInsuranceId
              ? {
                  ...p,
                  productSelected: !p.productSelected,
                }
              : p
          ),
        };
      });
    },
    isProductSelected: (buyInsuranceId: string) => {
      const product = get().productsInBuyInsurance.find(
        (p) => p.buyInsuranceId === buyInsuranceId
      );
      return product?.productSelected ?? false;
    },
    addRecommendedProducts: (products: ProductForSales[]) => {
      const productsInBuyInsurance = get().productsInBuyInsurance;
      const productsNotAlreadyInBuyInsurance = products.filter(
        (p) =>
          !productsInBuyInsurance?.find(
            (pib) => pib.cmsProductForSale.productLineId === p.productLineId
          )
      );

      set((state) => ({
        productsInBuyInsurance: [
          ...state.productsInBuyInsurance,
          ...productsNotAlreadyInBuyInsurance.map<BuyInsuranceProduct>((p) => ({
            cmsProductForSale: p,
            buyInsuranceId: randomString(),
            productSelected: false,
            shortDescription: p.shortDescription,
            objectFields: p.objectFields,
            recommendation: {
              recommended: true,
              modelId: p.modelId,
              requestID: p.requestId,
              feedbackProvided: false,
            },
          })),
        ],
      }));
    },
  }));
};
