const ForsiaIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 21.3942H3C1.48 21.3942 0.25 20.1642 0.25 18.6442V9.51416C0.25 8.70416 0.61 7.93416 1.23 7.40416L8.23 1.53416C9.25 0.67416 10.74 0.67416 11.77 1.53416L18.77 7.40416C19.39 7.92416 19.75 8.69416 19.75 9.51416V18.6442C19.75 20.1642 18.52 21.3942 17 21.3942ZM9.2 2.68416L2.2 8.55416C1.92 8.79416 1.75 9.14416 1.75 9.51416V18.6442C1.75 19.3342 2.31 19.8942 3 19.8942H17C17.69 19.8942 18.25 19.3342 18.25 18.6442V9.51416C18.25 9.14416 18.09 8.79416 17.8 8.55416L10.8 2.68416C10.33 2.29416 9.66 2.29416 9.19 2.68416H9.2Z"
      fill="currentColor"
    />
  </svg>
);

export default ForsiaIcon;
