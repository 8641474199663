
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { CacheProvider, EmotionCache, ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/nextjs';
import amplitude from 'amplitude-js';
import TopBar from 'components/app-bar/app-bar';
import AppContainer from 'components/app-container/app-container';
import { AutomaticInsurance } from 'components/automatic-insurance/automatic-insurance';
import IconBreadcrumbs from 'components/breadcrumb/breadcrumb';
import CookieConsent from 'components/cookie-consent/cookie-consent';
import Footer from 'components/footer/footer';
import LoadingComponent from 'components/loading-component/loading-component';
import UpdateContactModal from 'components/modal/update-contact-modal';
import Notifications from 'components/notifications/notifications';
import daLocale from 'date-fns/locale/da';
import { NextComponentType } from 'next';
import { SessionProvider, useSession } from 'next-auth/react';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Amplitude, AmplitudeProvider, useAmplitude, } from 'react-amplitude-hooks';
import { useCookie } from 'react-use';
import { BuyInsuranceStore, createBuyInsuranceStore, } from 'stores/buy-insurance-store';
import { SWRConfig } from 'swr';
import { getTheme } from 'theme/themeSelector';
import { AnalyticsEvent } from 'utility/analytics/events';
import { fetcherWithoutWrapper } from 'utility/api';
import { logout } from 'utility/auth/auth';
import { isBrowser } from 'utility/common';
import { IConsentCookie } from 'utility/cookies/consent-cookie.type';
import { ConsentType } from 'utility/cookies/cookie-consent-types';
import createEmotionCache from 'utility/createEmotionCache';
import { BrandDetails } from 'utility/node/sanity/brandDetails';
import { SentryGroup, addMessageWithGroup } from 'utility/sentry';
import './styles.scss';
import { NativeAppMessage, sendNativeAppMessage } from 'utility/nativeApp';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
    serverSideBrandDetails?: BrandDetails;
    Component: NextComponentType & {
        auth?: boolean;
        extendedHeader?: boolean;
        disableFooter?: boolean;
        disableBreadcrumb?: boolean;
        noContainerPadding?: boolean;
        title?: string;
    };
}
export const desktopMenuWidth = 270;
export const BrandContext = createContext<{
    details: BrandDetails;
} | undefined>(undefined);
export const BasketContext = createContext<BuyInsuranceStore | null>(null);
export const AppContext = createContext<{
    isApp: Boolean;
} | null>(null);
const App = ({ serverSideBrandDetails, Component, pageProps, emotionCache = clientSideEmotionCache, }: MyAppProps) => {
    const brandDetails = serverSideBrandDetails || pageProps?.brandDetails;
    const app = pageProps?.isApp || false;
    const theme = getTheme(brandDetails?.brandAbbreviation);
    const store = useRef(createBuyInsuranceStore({
        productsInBuyInsurance: pageProps?.basket,
        recommendedProducts: pageProps?.recommendedProducts,
    })).current;
    useEffect(() => {
        const setVh = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        window.addEventListener('resize', () => {
            setVh();
        });
    }, []);
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    return (<SessionProvider session={pageProps.session} refetchInterval={5 * 60} // 5 minutes
     refetchOnWindowFocus={true}>
      <AppContext.Provider value={{ isApp: app }}>
        <BrandContext.Provider value={{ details: brandDetails }}>
          <AmplitudeComponent>
            <SentryProvider>
              <PageViewLogger>
                <SWRConfig value={{
            fetcher: fetcherWithoutWrapper,
            provider: () => localStorageProvider(isClient, app),
        }}>
                  <SnackbarProvider preventDuplicate={true} maxSnack={99} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={daLocale}>
                      <MuiThemeProvider theme={theme}>
                        <CacheProvider value={emotionCache}>
                          <ThemeProvider theme={theme}>
                            {Component.auth !== false ? (<Auth>
                                <BasketContext.Provider value={store}>
                                  <>
                                    <Head>
                                      <title>
                                        {Component.title || 'Min side'}
                                      </title>
                                      <meta name="viewport" content="initial-scale=1, width=device-width, viewport-fit=cover"/>
                                    </Head>
                                    <CssBaseline />

                                    <Notifications sx={{
                ml: {
                    xs: 0,
                    md: `${desktopMenuWidth}px`,
                },
            }} notifications={pageProps.notifications}/>
                                    {pageProps.contactDetailsRequestUpdate && (<UpdateContactModal show={pageProps.contactDetailsRequestUpdate}/>)}
                                    <TopBar extended={Component.extendedHeader}/>
                                    <Box flex="1 0 auto" sx={{
                pt: 0,
                mt: Component.extendedHeader ? -40 : 0,
                width: {
                    md: `calc(100% - ${desktopMenuWidth}px)`,
                },
                ml: { md: `${desktopMenuWidth}px` },
            }}>
                                      <AppContainer disableFooter={Component.disableFooter} extendedHeader={Component.extendedHeader} noContainerPadding={Component.noContainerPadding}>
                                        {!Component.disableBreadcrumb && (<IconBreadcrumbs />)}
                                        <Component {...pageProps}/>
                                      </AppContainer>
                                      {!Component.disableFooter && <Footer />}
                                    </Box>
                                    <CookieConsent />
                                    <AutomaticInsurance openPopup={pageProps.showAutomaticInsurancePopup}/>
                                  </>
                                </BasketContext.Provider>
                              </Auth>) : (<Component {...pageProps}/>)}
                          </ThemeProvider>
                        </CacheProvider>
                      </MuiThemeProvider>
                    </LocalizationProvider>
                  </SnackbarProvider>
                </SWRConfig>
              </PageViewLogger>
            </SentryProvider>
          </AmplitudeComponent>
        </BrandContext.Provider>
      </AppContext.Provider>
    </SessionProvider>);
};
const SentryProvider = ({ children }: {
    children: JSX.Element;
}) => {
    const { data: session } = useSession();
    if (session) {
        Sentry.configureScope((scope) => {
            scope.setTag('userId', session.user?.id);
            scope.setContext('user', session.user as any);
            scope.setUser({ id: session.user?.id });
        });
    }
    return children;
};
const Auth = ({ children }: {
    children: JSX.Element;
}) => {
    const { logEvent } = useAmplitude();
    const { status, data: session } = useSession({
        required: true,
        onUnauthenticated() {
            window.location.href = '/login';
        },
    });
    const brand = useContext(BrandContext);
    var logoutTriggered = useRef(session && !session?.user?.partyId);
    useEffect(() => {
        if (session && !session?.user?.partyId) {
            logout(logEvent, brand.details.links.logoutRedirectUrl).catch((e) => {
                addMessageWithGroup({
                    message: e,
                    group: SentryGroup.auth,
                    tag: 'logout',
                    printToConsole: true,
                    consoleMessage: e,
                });
            });
            logoutTriggered.current = true;
        }
    });
    if (logoutTriggered.current) {
        return <LoadingComponent fullPage={true}/>;
    }
    if (status === 'loading') {
        return <LoadingComponent fullPage={true}/>;
    }
    return children;
};
const AmplitudeComponent = ({ children }: {
    children: JSX.Element;
}) => {
    const { data: session, status } = useSession();
    const [consentCookieValue] = useCookie('cookie_consent');
    const consentCookie: IConsentCookie | undefined = consentCookieValue
        ? JSON.parse(consentCookieValue)
        : undefined;
    const allowAmplitudeCookies = consentCookie?.consents_approved.includes(ConsentType.STATISTIC);
    const { publicRuntimeConfig } = getConfig();
    if (!isBrowser()) {
        return children;
    }
    return (<AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={publicRuntimeConfig.amplitudeApiKey} userId={session?.user?.id} config={{
            serverZone: 'EU',
            serverZoneBasedApi: true,
            storage: allowAmplitudeCookies ? 'cookies' : 'none',
        }}>
      <Amplitude userProperties={{ ...(session?.user || {}) }} eventProperties={{
            app: {
                name: 'minside',
                version: process.env.NEXT_PUBLIC_VERSION,
                environment: publicRuntimeConfig.environment,
            },
            page: {
                url: location.href,
                path: location.pathname,
                origin: location.origin,
            },
        }}>
        {children}
      </Amplitude>
    </AmplitudeProvider>);
};
const PageViewLogger = ({ children }: {
    children: JSX.Element;
}) => {
    const { logEvent } = useAmplitude();
    const router = useRouter();
    const { data: session } = useSession();
    useEffect(() => {
        if (isBrowser()) {
            sendNativeAppMessage(NativeAppMessage.DID_FINISH_LOADING);
            logEvent(AnalyticsEvent.PAGE_VIEW + router.pathname, {
                ...router.query,
            });
            (window as any).clarity('identify', session?.user?.id);
            (window as any).clarity('set', 'pathname', router.pathname);
        }
    }, [router.pathname, logEvent, router.query, session?.user?.id]);
    return children;
};
const localStorageProvider = (isClient: boolean, app: boolean) => {
    if (isClient && app) {
        const mapKey = 'minside-app-cache';
        const map = new Map<string, unknown>(JSON.parse(localStorage.getItem(mapKey) || '[]'));
        // Before unloading the app, we write back all the data into `localStorage`.
        window.addEventListener('beforeunload', () => {
            const appCache = JSON.stringify(Array.from(map.entries()));
            localStorage.setItem(mapKey, appCache);
        });
        return map;
    }
    return new Map<string, unknown>();
};
const __Next_Translate__Page__18fc8475825__ = App;

    export default __appWithI18n(__Next_Translate__Page__18fc8475825__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  