import { useTheme } from '@emotion/react';
import { ArrowBack, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useParty } from 'hooks/api/useParty';
import { useTranslation } from 'hooks/support/useTranslation';
import Image from 'next/image';
import { BrandContext } from 'pages/_app';
import { useContext, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import Confetti from 'react-confetti';
import { AnalyticsEvent } from 'utility/analytics/events';
import { postRequest } from 'utility/api';
import { ApiRoute } from 'utility/node/routes';

export const AutomaticInsurance = ({ openPopup = false }) => {
  const [open, setOpen] = useState(openPopup);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation('automatic-insurance');
  const brand = useContext(BrandContext);
  const { mutateParty, party } = useParty();
  const { logEvent } = useAmplitude();

  const teddyUrl = brand.details?.media?.successImage?.imageUrl;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleActivateRecommendations = async () => {
    await postRequest(ApiRoute.PARTY_ME, {
      productrecommendation: 1,
      riskrecommendation: 1,
    });
    await mutateParty();
    logEvent(AnalyticsEvent.AUTOMATIC_RECOMMENDATION_TURN_ON, {
      partyId: party?.id,
    });
    handleClose();
  };

  const handleNotActivatingRecommendations = async () => {
    await postRequest(ApiRoute.PARTY_ME, {
      productrecommendation: 0,
      riskrecommendation: 0,
    });
    await mutateParty();
    logEvent(AnalyticsEvent.AUTOMATIC_RECOMMENDATION_NOT_NOW, {
      partyId: party?.id,
    });
    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { overflowX: 'hidden', borderRadius: 3 } }}
      >
        {teddyUrl && (
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Image
              alt=""
              height={isMobile ? 115 : 230}
              width={isMobile ? 122 : 245}
              src={teddyUrl}
            />
            <Confetti
              colors={[
                `${theme.palette.primary.main}`,
                `${theme.palette.primary.dark}`,
                `${theme.palette.primary.light}`,
              ]}
              gravity={0.02}
              height={isMobile ? 150 : 300}
            />
          </DialogTitle>
        )}
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            textAlign: 'center',
            textWrap: 'balance',
          }}
        >
          <Typography variant="overline">{t('overline')}</Typography>
          <Typography variant="h4" color="primary.main">
            {t('title')}
          </Typography>
          <Typography>{t('body')}</Typography>
          <Typography variant="body2">{t('body2')}</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mb: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={handleActivateRecommendations}
            sx={{ width: '50%' }}
          >
            {t('buttonTurnOn')}
          </Button>
          <Button
            onClick={handleNotActivatingRecommendations}
            fullWidth
            sx={{ width: '50%' }}
          >
            {t('buttonNotNow')}
          </Button>
          <Box
            display="flex"
            gap={0.5}
            flexDirection={isMobile ? 'column' : 'row'}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="caption">{t('caption')}</Typography>
            <Link
              underline="none"
              sx={{ cursor: 'pointer', fontSize: '0.75rem' }}
              onClick={() => {
                setAdditionalInfo(true);
                setOpen(false);
              }}
            >
              {t('buttonReadMore')}
            </Link>
          </Box>
        </DialogActions>
      </Dialog>
      <AdditionalInfo
        additionalInfo={additionalInfo}
        setAdditionalInfo={setAdditionalInfo}
        setAutomaticInsurance={setOpen}
        t={t}
      />
    </>
  );
};

const AdditionalInfo = ({
  additionalInfo,
  setAdditionalInfo,
  setAutomaticInsurance,
  t,
}) => {
  const handleClose = () => {
    setAdditionalInfo(false);
    setAutomaticInsurance(true);
  };
  return (
    <Dialog open={additionalInfo} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.secondary.main,
            alignSelf: 'flex-end',
          }}
        >
          <CloseOutlined sx={{ width: 35, height: 35 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography variant="subtitle2">{t('additionalInfo.title')}</Typography>
        <Typography variant="body2" paragraph>
          {t('additionalInfo.body')}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mb: 3,
        }}
      >
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'left',
          }}
          variant="text"
          startIcon={<ArrowBack />}
          fullWidth
          onClick={handleClose}
        >
          {t('additionalInfo.backButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
