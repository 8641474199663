var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.9.1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: 'https://5c29064ebb6746f7a3fbdc98073f28d7@o1260081.ingest.sentry.io/6437906',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: publicRuntimeConfig.sentryTraceSampleRate || 0,
  // Sample rate on errors
  sampleRate: publicRuntimeConfig.sentryErrorSampleRate || 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: publicRuntimeConfig.environment,

  integrations: [
    new Sentry.BrowserTracing({
      // custom options
    }),
    new Sentry.BrowserProfilingIntegration(),
  ],

  initialScope: (scope) => {
    return scope;
  },
});
