import * as React from 'react';

const InstagramIcon = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="currentColor" />
    <path
      d="M14.825 8.75H22.175C24.975 8.75 27.25 11.025 27.25 13.825V21.175C27.25 22.521 26.7153 23.8118 25.7636 24.7636C24.8118 25.7153 23.521 26.25 22.175 26.25H14.825C12.025 26.25 9.75 23.975 9.75 21.175V13.825C9.75 12.479 10.2847 11.1882 11.2364 10.2364C12.1882 9.28469 13.479 8.75 14.825 8.75V8.75ZM14.65 10.5C13.8146 10.5 13.0134 10.8319 12.4226 11.4226C11.8319 12.0134 11.5 12.8146 11.5 13.65V21.35C11.5 23.0912 12.9087 24.5 14.65 24.5H22.35C23.1854 24.5 23.9866 24.1681 24.5774 23.5774C25.1681 22.9866 25.5 22.1854 25.5 21.35V13.65C25.5 11.9087 24.0912 10.5 22.35 10.5H14.65ZM23.0938 11.8125C23.3838 11.8125 23.662 11.9277 23.8671 12.1329C24.0723 12.338 24.1875 12.6162 24.1875 12.9062C24.1875 13.1963 24.0723 13.4745 23.8671 13.6796C23.662 13.8848 23.3838 14 23.0938 14C22.8037 14 22.5255 13.8848 22.3204 13.6796C22.1152 13.4745 22 13.1963 22 12.9062C22 12.6162 22.1152 12.338 22.3204 12.1329C22.5255 11.9277 22.8037 11.8125 23.0938 11.8125ZM18.5 13.125C19.6603 13.125 20.7731 13.5859 21.5936 14.4064C22.4141 15.2269 22.875 16.3397 22.875 17.5C22.875 18.6603 22.4141 19.7731 21.5936 20.5936C20.7731 21.4141 19.6603 21.875 18.5 21.875C17.3397 21.875 16.2269 21.4141 15.4064 20.5936C14.5859 19.7731 14.125 18.6603 14.125 17.5C14.125 16.3397 14.5859 15.2269 15.4064 14.4064C16.2269 13.5859 17.3397 13.125 18.5 13.125V13.125ZM18.5 14.875C17.8038 14.875 17.1361 15.1516 16.6438 15.6438C16.1516 16.1361 15.875 16.8038 15.875 17.5C15.875 18.1962 16.1516 18.8639 16.6438 19.3562C17.1361 19.8484 17.8038 20.125 18.5 20.125C19.1962 20.125 19.8639 19.8484 20.3562 19.3562C20.8484 18.8639 21.125 18.1962 21.125 17.5C21.125 16.8038 20.8484 16.1361 20.3562 15.6438C19.8639 15.1516 19.1962 14.875 18.5 14.875Z"
      fill="white"
    />
  </svg>
);

export default InstagramIcon;
