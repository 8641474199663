export enum AnalyticsEvent {
  PAGE_VIEW = 'page view ',
  LOGIN = 'login trigged',
  LOGIN_SUCCESS = 'login succeeded',
  VALIDATE_SSN = 'validate ssn',
  LOGOUT = 'logout',
  FILE_CLAIM_NEXT_QUESTION = 'file claim next question',
  FILE_CLAIM_EDIT_ANSWER = 'file claim edit answer',
  FILE_CLAIM_NEXT_QUESTION_VALIDATION_ERROR = 'file claim question with validation error',
  FILE_CLAIM_SUCCESS = 'file claim success',
  FILE_CLAIM_CURRENT_QUESTION = 'file claim current question',
  CARD_CLICK = 'card click',
  BREAD_CRUMB_CLICK = 'breadcrumb click',
  ACCEPT_OFFER_STARTED = 'accept offer started',
  ACCEPT_OFFER_NAVIGATE_FORWARD = 'accept offer navigate forward',
  ACCEPT_OFFER_NAVIGATE_BACK = 'accept offer navigate backward',
  ACCEPT_OFFER_COMPLETE = 'accept offer complete',
  NOTIFICATION_DISMISSED = 'notification dismissed',
  NOTIFICATION_CLICKED = 'notification clicked',
  DOWNLOAD_POLICY_LIST_CLICKED = 'download policy list clicked',
  DOWNLOAD_CLAIM_LIST_CLICKED = 'download claim list clicked',
  BUY_RISK_CLICKED = 'buy risk clicked',
  BUY_RISK_CALL_ME_CLICKED = 'buy risk call me clicked',
  REMOVE_RISK_CLICKED = 'remove risk clicked',
  REMOVE_RISK_CALL_ME_CLICKED = 'remove risk call me clicked',
  PERIODICAL_CONTACT_DETAILS_MODAL_SHOWN = 'periodical contact details modal shown',
  PERIODICAL_CONTACT_DETAILS_MODAL_CLOSED = 'periodical contact details modal closed',
  PERIODICAL_CONTACT_DETAILS_MODAL_UPDATED = 'periodical contact details modal updated',
  WEBAUTH_LOGIN_TRIGGERED = 'webauth login triggered',
  WEBAUTH_LOGIN_SUCCESS = 'webauth login success',
  WEBAUTH_LOGIN_FAILED = 'webauth login success',
  WEBAUTH_REGISTRATION_TRIGGERED = 'webauth registration triggered',
  WEBAUTH_REGISTRATION_SUCCESS = 'webauth registration success',
  WEBAUTH_REGISTRATION_FAILED = 'webauth registration success',
  BUY_INSURANCE_FLOW_STARTED = 'start of buy insurance flow',
  BUY_INSURANCE_FLOW_NAVIGATE_FORWARD = 'navigate forward of buy insurance flow',
  BUY_INSURANCE_FLOW_NAVIGATE_BACK = 'navigate backward buy insurance flow',
  BUY_INSURANCE_FLOW_COMPLETE = 'complete of buy insurance flow',
  ADD_PRODUCT_TO_BUY_INSURANCE_FLOW = 'add a product to buy insurance flow',
  REMOVE_PRODUCT_FROM_BUY_INSURANCE_FLOW = 'remove a product from buy insurance flow',
  BASKET_QUOTE_CLICKED = 'quote clicked in a basket',
  BASKET_QUOTE_REMOVED = 'quote removed in a basket',
  BASKET_QUOTE_RESTORED = 'quote restored in a basket',
  BASKET_RISK_EDITED = 'risk edited in a basket',
  BASKET_FIELD_EDITED = 'field edited in a basket',
  AUTOMATIC_RECOMMENDATION_TURN_ON = 'automatic recommendation turn on',
  AUTOMATIC_RECOMMENDATION_NOT_NOW = 'automatic recommendation not now',
}
