import * as React from 'react';

const PatchIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75988 19.8942C4.75988 19.8942 3.81988 19.5042 3.10988 18.7942L1.34988 17.0342C-0.110117 15.5742 -0.110117 13.1942 1.34988 11.7342L11.5899 1.49417C13.0499 0.0341699 15.4299 0.0341699 16.8899 1.49417L18.6499 3.25417C19.3599 3.96417 19.7499 4.90417 19.7499 5.90417C19.7499 6.90417 19.3599 7.84417 18.6499 8.55417L8.40988 18.7942C7.69988 19.5042 6.75988 19.8942 5.75988 19.8942ZM2.40988 15.9742L4.16988 17.7342C5.01988 18.5842 6.49988 18.5842 7.34988 17.7342L17.5899 7.49417C18.0099 7.06417 18.2499 6.50417 18.2499 5.90417C18.2499 5.30417 18.0199 4.73417 17.5899 4.31417L15.8299 2.55417C14.9799 1.70417 13.4999 1.70417 12.6499 2.55417L2.40988 12.7942C1.52988 13.6742 1.52988 15.0942 2.40988 15.9742Z"
      fill="currentColor"
    />
    <path
      d="M14.2399 19.8942C13.2799 19.8942 12.3199 19.5242 11.5899 18.7942L9.46994 16.6742C9.17994 16.3842 9.17994 15.9042 9.46994 15.6142C9.75994 15.3242 10.2399 15.3242 10.5299 15.6142L12.6499 17.7342C13.5299 18.6142 14.9599 18.6142 15.8299 17.7342L17.5899 15.9742C18.4699 15.0942 18.4699 13.6742 17.5899 12.7942L15.4699 10.6742C15.1799 10.3842 15.1799 9.90423 15.4699 9.61423C15.7599 9.32423 16.2399 9.32423 16.5299 9.61423L18.6499 11.7342C20.1099 13.1942 20.1099 15.5742 18.6499 17.0342L16.8899 18.7942C16.1599 19.5242 15.1999 19.8942 14.2399 19.8942Z"
      fill="currentColor"
    />
    <path
      d="M4 10.8942C3.81 10.8942 3.62 10.8242 3.47 10.6742L1.35 8.5542C0.64 7.8442 0.25 6.9042 0.25 5.9042C0.25 4.9042 0.64 3.9642 1.35 3.2542L3.11 1.4942C4.53 0.0741992 7 0.0741992 8.41 1.4942L10.53 3.6142C10.82 3.9042 10.82 4.3842 10.53 4.6742C10.24 4.9642 9.76 4.9642 9.47 4.6742L7.35 2.5542C6.5 1.7042 5.02 1.7042 4.17 2.5542L2.41 4.3142C1.98 4.7342 1.75 5.3042 1.75 5.9042C1.75 6.5042 1.98 7.0742 2.41 7.4942L4.53 9.6142C4.82 9.9042 4.82 10.3842 4.53 10.6742C4.38 10.8242 4.19 10.8942 4 10.8942Z"
      fill="currentColor"
    />
    <path
      d="M7.99994 14.8942C7.80994 14.8942 7.61994 14.8242 7.46994 14.6742L5.46994 12.6742C5.17994 12.3842 5.17994 11.9042 5.46994 11.6142C5.75994 11.3242 6.23994 11.3242 6.52994 11.6142L8.52994 13.6142C8.81994 13.9042 8.81994 14.3842 8.52994 14.6742C8.37994 14.8242 8.18994 14.8942 7.99994 14.8942Z"
      fill="currentColor"
    />
    <path
      d="M10.9999 11.8942C10.8099 11.8942 10.6199 11.8242 10.4699 11.6742L8.46994 9.67423C8.17994 9.38423 8.17994 8.90423 8.46994 8.61423C8.75994 8.32423 9.23994 8.32423 9.52994 8.61423L11.5299 10.6142C11.8199 10.9042 11.8199 11.3842 11.5299 11.6742C11.3799 11.8242 11.1899 11.8942 10.9999 11.8942Z"
      fill="currentColor"
    />
    <path
      d="M13.9999 8.89423C13.8099 8.89423 13.6199 8.82423 13.4699 8.67423L11.4699 6.67423C11.1799 6.38423 11.1799 5.90423 11.4699 5.61423C11.7599 5.32423 12.2399 5.32423 12.5299 5.61423L14.5299 7.61423C14.8199 7.90423 14.8199 8.38423 14.5299 8.67423C14.3799 8.82423 14.1899 8.89423 13.9999 8.89423Z"
      fill="currentColor"
    />
  </svg>
);

export default PatchIcon;
