import { Avatar as MuiAvatar, Theme } from '@mui/material';
import { SxProps } from '@mui/material';

const stringAvatar = (name: string) => {
  return {
    children: `${
      name?.split(' ').length > 1
        ? name?.split(' ')[0][0] + name?.split(' ')[1][0]
        : 'xx'
    }`,
  };
};

const Avatar = ({ name, sx }: { name: string; sx?: SxProps<Theme> }) => {
  return (
    <MuiAvatar
      sx={{ bgcolor: 'primary.main', ...sx }}
      alt={name}
      {...stringAvatar(name)}
    />
  );
};

export default Avatar;
