import useSWR from 'swr';
import { Party } from 'utility/node/party/party.model';
import { ApiRoute } from 'utility/node/routes';

export const useParty = () => {
  const { data, error, mutate } = useSWR<Party>(ApiRoute.PARTY_ME);

  return {
    party: data,
    partyErrors: error,
    mutateParty: mutate,
    partyLoading: !data && !error,
  };
};
