import { useSession } from 'next-auth/react';
import { TranslationQuery } from 'next-translate';
import nextTranslate from 'next-translate/useTranslation';
import { useContext } from 'react';
import { BrandContext } from 'pages/_app';
import { getCustomerTypeFromSession } from 'utility/customerTypes';

export declare type Translate = <T = string>(
  key: string,
  customerTypeSpecificTranslation?: boolean,
  query?: TranslationQuery | null,
  options?: {
    returnObjects?: boolean;
    fallback?: string | string[];
    default?: string;
    ns?: string;
  }
) => T;

export const useTranslation = (namespace: string): { t: Translate } => {
  const brand = useContext(BrandContext);
  const { t } = nextTranslate(namespace);
  const { data: session } = useSession();

  const localT = (
    key: string,
    customerTypeSpecificTranslation?: boolean,
    query?: TranslationQuery | null,
    options?: {
      returnObjects?: boolean;
      fallback?: string | string[];
      default?: string;
      ns?: string;
    }
  ) => {
    let customKey = key;
    if (customerTypeSpecificTranslation) {
      const customerType = getCustomerTypeFromSession(session).toLowerCase();
      const lastIndexOfDot = key.lastIndexOf('.');

      customKey =
        key.substring(0, lastIndexOfDot + 1) +
        customerType +
        '-' +
        key.substring(lastIndexOfDot + 1, key.length);
    }

    return t(
      customKey,
      {
        customerName: session?.user?.name,
        ...brand?.details?.translations,
        ...query,
      },
      { ...options }
    );
  };

  return { t: localT as Translate };
};
