// Generated by https://quicktype.io
export class ITiaPolicy {
  constructor() {}
  status: Status = Status.UNKNOWN;
  policyNo: number;
  id: number;
  policyNoAlt: string;
  policyNoSort: string;
  policyStatus: PolicyStatus;
  productId: string;
  coverStartDate: Date;
  coverChangedDate: Date;
  coverEndDate: Date;
  renewalDate: Date;
  yearStartDate: Date;
  firstStartDate: Date;
  quoteExpiryDate: Date;
  cancelCode: number;
  newest: string;
  previousId: number;
  transactionId: number;
  discountType: string;
  expiryCode: number;
  lapseCode: number;
  policyAutRenewal: number;
  serviceAgentNo: number;
  discountPercentage: number;
  policyYear: number;
  transactionType: string;
  instalmentMethod: number;
  instalmentPlanType: number;
  paymentMethod: string;
  meansPayNo?: number;
  accountNo: number;
  siteId: number;
  policyHolderId: number;
  forceGlobalMta: boolean;
  masterPolicy: boolean;
  policyLines: ITiaPolicyLine[];
  agents: ITiaAgent[];
  mtaPreviousId?: number;
}

export interface ITiaAgent {
  agentPositionOnPolicy: number;
  agentRole: number;
  agentNo: number;
  commissionGroup: string;
}

export class ITiaPolicyLine {
  policyNo: number;
  policyLineNo: number;
  id: number;
  policyYear: number;
  firstStartDate: string;
  yearStartDate: string;
  coverStartDate: string;
  coverChangedDate: string;
  coverEndDate: string;
  renewalDate: string;
  cancelCode: number;
  expiryDate: string;
  newest: string;
  previousId: number;
  transactionId: number;
  status: string;
  transactionCode: number;
  productLineId: string;
  productLineVersionNo: number;
  sectionNo: number;
  discountType: string;
  currencyCode: string;
  pricePaid: number;
  tariffPrice: number;
  transactionCost: number;
  productTax4: number;
  policyId: number;
  shortDescription: string;
  temporaryInsurance: boolean;
  siteId: number;
  transactionCause: string;
  expiryCause: string;
  nextInsuranceCompany: string;
  previousInsuranceCompany: string;
  instalmentPlanType: number;
  proRataCalendar: string;
  mtaPreviousId: number;
  mtaStartDate: string;
  ignoreBackdatedVersions: boolean;
  webTotalPremium: number;
  premiumRiskSplit: ITiaPremiumRiskSplit[];
  policyObjects: ITiaPolicyObject[];
  policyClauses: ITiaPolicyClause[];
  groupAgreement?: string;
}

export interface ITiaPremiumRiskSplit {
  policyLineId: number;
  riskNo: number;
  factor: number;
  siteId: number;
}

export interface ITiaPolicyObject {
  cancelCode: number;
  coverChangedDate: Date;
  coverEndDate: Date;
  coverStartDate: Date;
  fields: ITiaPolicyObjectField[];
  id: number;
  informationOnly: boolean;
  locationId: number;
  mtaPreviousId: number;
  newest: string;
  nextId: number;
  objectId: string;
  objectNameId: number;
  objectRiskSlaves: ITiaPolicyObjectRiskSlave[];
  parentPolicyObjectNo: number;
  partyId: number;
  policyClauses: ITiaPolicyClause[];
  policyLineNo: number;
  policyObjectNo: number;
  previousId: number;
  quotedPolicyObjectId: number;
  shortDescription: string;
  siteId: number;
  status: string;
  subobjects: ITiaPolicyObjectSubobject[];
  sumInsured: number;
  tariffPrice: number;
  transactionId: number;
  type: string;
  typeId: string;
}

export interface ITiaPolicyClause {
  cancelCode?: number;
  clauseId: number;
  clauseNo: number;
  coverChangedDate: Date;
  coverEndDate: Date;
  coverStartDate: Date;
  creation: number;
  id: number;
  newest: string;
  nextId: number;
  optional: boolean;
  policyLineId: number;
  policyLineNo: number;
  policyObjectNo: number;
  previousId: number;
  productLineId: string;
  productLineVersionNo: number;
  riskNo: number;
  siteId: number;
  sortNo: number;
  subriskNo: number;
  text: string;
  transactionId: number;
  type: number;
  variables: string;
}

export interface ITiaPolicyObjectField {
  name: string;
  type: 'String' | 'BigDecimal' | 'Date'; //i don't know if this list is correct or complete, Date not yet seen in the wild
  value: string;
}

export interface ITiaPolicyObjectSubobject {
  fields: ITiaPolicyObjectField[];
  policyObjectId: number;
  siteId: number;
  subobjectNo: number;
  typeId: string;
}

export interface ITiaPolicyObjectRiskSlave {
  coiSplitFactor: number;
  instalmentPlanType: number;
  policyClauses: ITiaPolicyClause[];
  policyObjectId: number;
  riskCovered: boolean;
  riskDiscount: number;
  riskExcess: number;
  riskFlex1: string;
  riskFlex2: string;
  riskFlex3: string;
  riskFlex4: string;
  riskNo: number;
  riskSum: number;
  riskTerms: string;
  siteId: number;
  tariffPrice: number;
}

export interface ITiaPolicyInQuote {
  policyNo: number;
  pricePaid: number;
  productLineId: string;
  firstStartDate: Date;
  status: 'DECLINED' | 'IN_FORCE' | 'PENDING';
  shortDesc: string;
}
export interface ITiaAllQuoteResponse {
  group: string;
  description: string;
  allQuote: {
    allQuoteArray: ITiaAllQuote[];
  };
}
export interface ITiaAllQuote {
  requestId: number;
  partyId: number;
  expiryDate: Date;
  requestUser: string;
  policyInQuoteArray: ITiaPolicyInQuote[];
  status: 'ACCEPTED' | 'OPEN' | 'EXPIRED' | 'REJECTED';
}

export enum PolicyStatus {
  P = 'P',
  Q = 'Q',
  PS = 'PS',
  QS = 'QS',
}

export enum Status {
  DECLINED = 'DECLINED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  LATER_IN_FORCE = 'LATER_IN_FORCE',
  UNKNOWN = 'UNKNOWN',
  QUOTE = 'QUOTE',
  AWAITING_INPUT = 'AWAITING_INPUT',
  CANCELED_QUOTE = 'CANCELED_QUOTE',
  SUSPENDED_QUOTE = 'SUSPENDED_QUOTE',
  EXPIRED_QUOTE = 'EXPIRED_QUOTE',
}

export interface IPreviousInsuranceCompanies {
  group: string;
  description: string;
  prevInsur: PrevInsur;
}

export interface PrevInsur {
  prevInsurerCodeArray: PrevInsurerCodeArray[];
}

export interface PrevInsurerCodeArray {
  prevInsurerCode: string;
  description: string;
  nameIdNo: number;
}
