import { Alert, Box, Collapse, SxProps, Theme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Notification } from 'utility/node/sanity/notification.model';
import { TransitionGroup } from 'react-transition-group';
import CmsCardDetailsModal from '../modal/cms-card-details-modal';
import Link from '../link/link';
import { useContainerStore } from 'stores/container-store';
import { useTranslation } from 'hooks/support/useTranslation';
import { useCookie } from 'react-use';
import { useAmplitude } from 'react-amplitude-hooks';
import { AnalyticsEvent } from 'utility/analytics/events';

const Notifications = ({
  notifications,
  sx,
}: {
  notifications?: Notification[];
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslation('common');
  const notificationsContainerRef = useRef(null);
  const { logEvent } = useAmplitude();
  const [cardSlug, setCardSlug] = useState<string | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [cookieValue, updateCookie] = useCookie('notifications');
  const notificationCookieData: string[] = cookieValue
    ? JSON.parse(cookieValue)
    : [];

  const [notificationsToHide, setNotificationsToHide] = useState(
    notificationCookieData || []
  );
  const { setNotificationsHeight } = useContainerStore((state) => ({
    setNotificationsHeight: state.setNotificationsHeight,
  }));

  const handleCloseNotification = (slug: string) => {
    logEvent(AnalyticsEvent.NOTIFICATION_DISMISSED, { slug });
    const dismissedNotifications = [...notificationsToHide, slug];
    setNotificationsToHide(dismissedNotifications);
    updateCookie(JSON.stringify(dismissedNotifications), {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (notificationsContainerRef.current) {
        setNotificationsHeight(notificationsContainerRef.current.clientHeight);
      }
    });

    if (notificationsContainerRef.current) {
      resizeObserver.observe(notificationsContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const filteredNotifications = notifications?.filter(
    (n) => !notificationsToHide.includes(n.slug)
  );

  return (
    <>
      <CmsCardDetailsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        cmsData={notifications?.find((n) => n.slug === cardSlug)?.detailModal}
        slug={cardSlug}
      />
      <Box ref={notificationsContainerRef}>
        <TransitionGroup className="u">
          {filteredNotifications?.map((notification) => {
            return (
              <Collapse
                in={!notificationsToHide.includes(notification.slug)}
                key={notification.slug}
              >
                <Alert
                  variant="filled"
                  elevation={0}
                  sx={{
                    borderRadius: 0,
                    textAlign: 'center',
                    ...sx,
                    '.MuiAlert-message': { width: '100%' },
                  }}
                  severity={notification.type as any}
                  icon={false}
                  onClose={
                    notification.dissmissable
                      ? () => handleCloseNotification(notification.slug)
                      : null
                  }
                >
                  {notification.text}
                  {notification.detailModal && (
                    <Link
                      sx={{ ml: 2 }}
                      underline="none"
                      href="#"
                      color="inherit"
                      onClick={() => {
                        logEvent(AnalyticsEvent.NOTIFICATION_CLICKED, {
                          slug: notification.slug,
                        });
                        setCardSlug(notification.slug);
                        setShowModal(true);
                      }}
                    >
                      {t('notifications.showDetails')}
                    </Link>
                  )}
                </Alert>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Box>
    </>
  );
};

export default Notifications;
