import * as React from 'react';

const OffersIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8399 19.8942H3.15988C2.39988 19.8942 1.65988 19.5742 1.13988 19.0142C0.619883 18.4542 0.359883 17.6942 0.409883 16.9342L1.16988 7.01416C1.23988 6.10416 2.00988 5.39417 2.90988 5.39417H15.0599C15.9699 5.39417 16.7299 6.10416 16.8099 7.01416L17.5699 16.9342C17.6299 17.6942 17.3599 18.4542 16.8499 19.0142C16.3299 19.5742 15.5999 19.8942 14.8299 19.8942H14.8399ZM2.92988 6.89417C2.79988 6.89417 2.68988 6.99416 2.67988 7.12416L1.91988 17.0442C1.88988 17.3942 2.00988 17.7342 2.24988 17.9942C2.48988 18.2542 2.80988 18.3942 3.16988 18.3942H14.8499C15.1999 18.3942 15.5299 18.2542 15.7699 17.9942C16.0099 17.7342 16.1299 17.4042 16.0999 17.0442L15.3399 7.12416C15.3399 6.99416 15.2199 6.89417 15.0899 6.89417H2.92988Z"
      fill="currentColor"
    />
    <path
      d="M13 6.89417C12.59 6.89417 12.25 6.55416 12.25 6.14417V5.14417C12.25 3.35417 10.79 1.89417 9 1.89417C7.21 1.89417 5.75 3.35417 5.75 5.14417V6.14417C5.75 6.55416 5.41 6.89417 5 6.89417C4.59 6.89417 4.25 6.55416 4.25 6.14417V5.14417C4.25 2.52417 6.38 0.394165 9 0.394165C11.62 0.394165 13.75 2.52417 13.75 5.14417V6.14417C13.75 6.55416 13.41 6.89417 13 6.89417Z"
      fill="currentColor"
    />
  </svg>
);

export default OffersIcon;
