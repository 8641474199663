import * as React from 'react';

const BenefitsIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1699 17.5142C18.5199 17.8642 18.5199 18.4442 18.1699 18.7942C17.8199 19.1442 17.2399 19.1442 16.8899 18.7942C16.5399 18.4442 16.5399 17.8642 16.8899 17.5142C17.2399 17.1642 17.8199 17.1642 18.1699 17.5142Z"
      fill="currentColor"
    />
    <path
      d="M15.3001 13.5341C15.6501 13.8841 15.6501 14.4641 15.3001 14.8141C14.9501 15.1641 14.3701 15.1641 14.0201 14.8141C13.6701 14.4641 13.6701 13.8841 14.0201 13.5341C14.3701 13.1841 14.9501 13.1841 15.3001 13.5341Z"
      fill="currentColor"
    />
    <path
      d="M9.00996 8.93417C7.91996 8.93417 6.83996 8.52417 6.00996 7.69417C4.35996 6.04417 4.35996 3.34416 6.00996 1.69416C7.65996 0.044165 10.35 0.044165 12.01 1.69416C13.66 3.34416 13.66 6.04417 12.01 7.69417C11.18 8.52417 10.1 8.93417 9.00996 8.93417ZM9.00996 1.94416C8.30996 1.94416 7.60996 2.21416 7.06996 2.74416C5.99996 3.81417 5.99996 5.55416 7.06996 6.62417C8.13996 7.69417 9.87996 7.69417 10.95 6.62417C12.02 5.55416 12.02 3.81417 10.95 2.74416C10.42 2.21416 9.70996 1.94416 9.00996 1.94416Z"
      fill="currentColor"
    />
    <path
      d="M11.42 18.9342H2.01001C1.05001 18.9342 0.26001 18.1442 0.26001 17.1842V16.1842C0.26001 12.5942 4.71001 10.4442 9.01001 10.4442C10.64 10.4442 12.25 10.7442 13.65 11.3042C14.03 11.4542 14.22 11.8942 14.07 12.2742C13.92 12.6542 13.48 12.8442 13.1 12.6942C11.89 12.2142 10.44 11.9442 9.02001 11.9442C5.53001 11.9442 1.77001 13.5642 1.77001 16.1842V17.1842C1.77001 17.3242 1.88001 17.4342 2.02001 17.4342H11.43C11.84 17.4342 12.18 17.7742 12.18 18.1842C12.18 18.5942 11.84 18.9342 11.43 18.9342H11.42Z"
      fill="currentColor"
    />
    <path
      d="M16.01 21.9442C12.84 21.9442 10.26 19.3642 10.26 16.1942C10.26 14.6842 10.87 13.2442 11.97 12.1442C13.07 11.0442 14.5 10.4442 16.01 10.4442C19.18 10.4442 21.76 13.0242 21.76 16.1942C21.76 19.3642 19.18 21.9442 16.01 21.9442ZM16.01 11.9442C14.9 11.9442 13.85 12.3942 13.03 13.2042C12.21 14.0242 11.76 15.0842 11.76 16.1942C11.76 18.5342 13.67 20.4442 16.01 20.4442C18.35 20.4442 20.26 18.5342 20.26 16.1942C20.26 13.8542 18.35 11.9442 16.01 11.9442Z"
      fill="currentColor"
    />
    <path
      d="M14.26 18.6942C14.07 18.6942 13.88 18.6242 13.73 18.4742C13.44 18.1842 13.44 17.7042 13.73 17.4142L17.23 13.9142C17.52 13.6242 18 13.6242 18.29 13.9142C18.58 14.2042 18.58 14.6842 18.29 14.9742L14.79 18.4742C14.64 18.6242 14.45 18.6942 14.26 18.6942Z"
      fill="currentColor"
    />
  </svg>
);

export default BenefitsIcon;
