import * as React from 'react';

const SjfIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5688 2.84597C13.6879 2.27199 14.2496 1.90324 14.8235 2.02235L15.8436 2.23407C16.4175 2.35318 16.7862 2.91504 16.6671 3.48902L15.3293 9.93656C15.2102 10.5105 14.6484 10.8793 14.0745 10.7602L13.0544 10.5485C12.4806 10.4293 12.1119 9.86748 12.231 9.2935L13.5688 2.84597ZM14.6577 2.8218L15.6777 3.03352C15.8101 3.061 15.8952 3.19066 15.8677 3.32312L14.5299 9.77066C14.5025 9.90311 14.3728 9.98821 14.2404 9.96072L13.2203 9.74901C13.0879 9.72152 13.0028 9.59186 13.0303 9.4594L14.3681 3.01187C14.3956 2.87941 14.5252 2.79432 14.6577 2.8218Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1349 13.4331C21.7109 13.5415 22.0899 14.0964 21.9815 14.6725L21.7888 15.6965C21.6803 16.2726 21.1255 16.6517 20.5495 16.5432L14.0792 15.3251C13.5032 15.2166 13.1241 14.6617 13.2326 14.0856L13.4253 13.0616C13.5337 12.4856 14.0886 12.1065 14.6646 12.2149L21.1349 13.4331ZM21.1792 14.5215L20.9865 15.5454C20.9615 15.6784 20.8334 15.7659 20.7005 15.7408L14.2302 14.5227C14.0973 14.4977 14.0098 14.3696 14.0348 14.2367L14.2276 13.2127C14.2526 13.0797 14.3806 12.9923 14.5135 13.0173L20.9838 14.2354C21.1168 14.2605 21.2042 14.3885 21.1792 14.5215Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97954 21.9829C9.55631 22.0872 10.1084 21.7041 10.2127 21.1273L11.3843 14.6475C11.4886 14.0706 11.1056 13.5184 10.5288 13.4141L9.50367 13.2287C8.9269 13.1244 8.37479 13.5074 8.27049 14.0843L7.09889 20.5641C6.99459 21.141 7.3776 21.6932 7.95437 21.7975L8.97954 21.9829ZM8.09964 20.994L9.12481 21.1794C9.25791 21.2035 9.38532 21.1151 9.40939 20.982L10.581 14.5022C10.6051 14.3691 10.5167 14.2416 10.3836 14.2176L9.3584 14.0321C9.2253 14.0081 9.09789 14.0965 9.07382 14.2296L7.90222 20.7094C7.87815 20.8425 7.96654 20.9699 8.09964 20.994Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.84348 10.228C2.26986 10.1076 1.90245 9.54488 2.02285 8.97117L2.23685 7.95145C2.35725 7.37774 2.91986 7.01027 3.49349 7.13069L9.93703 8.48333C10.5107 8.60375 10.8781 9.16645 10.7577 9.74015L10.5437 10.7599C10.4233 11.3336 9.86065 11.7011 9.28702 11.5806L2.84348 10.228ZM2.8218 9.13889L3.0358 8.11916C3.06358 7.98677 3.19342 7.90197 3.32579 7.92976L9.76933 9.2824C9.90171 9.31019 9.9865 9.44004 9.95871 9.57244L9.74471 10.5922C9.71693 10.7246 9.58709 10.8094 9.45472 10.7816L3.01118 9.42893C2.8788 9.40114 2.79402 9.27128 2.8218 9.13889Z"
      fill="currentColor"
    />
  </svg>
);

export default SjfIcon;
