import { Brands } from 'utility/brands';
import { addMessageWithGroup, SentryGroup } from 'utility/sentry';
import defaultTheme from './default-theme';
import dfhTheme from './dfh-theme';
import forsiaTheme from './forsia-theme';

export const getTheme = (brand: Brands) => {
  switch (brand) {
    case Brands.DFHF:
      return dfhTheme;
    case Brands.SJF:
      return defaultTheme;
    case Brands.FORSIA:
      return forsiaTheme;
    default:
      const message = `Unknown brand: ${brand} returning default`;
      addMessageWithGroup({
        group: SentryGroup.common,
        message: message,
        printToConsole: true,
        consoleMessage: message,
        tag: 'getTheme',
      });
      return defaultTheme;
  }
};
