import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useRouter } from 'next/router';
import { useTranslation } from 'hooks/support/useTranslation';
import Link from '../link/link';
import { useClaim } from 'hooks/api/useClaim';
import { usePolicy } from 'hooks/api/usePolicy';
import { useAmplitude } from 'react-amplitude-hooks';
import { AnalyticsEvent } from 'utility/analytics/events';
import { AppContext } from 'pages/_app';

const ignoredPaths = ['offers'];

interface IBreadcrumbItem {
  href: string;
  text: string;
  icon?: React.ReactNode;
}

const IconBreadcrumbs = () => {
  const app = React.useContext(AppContext);
  const router = useRouter();
  const { t } = useTranslation('common');
  const { claimNo, policyNo, requestId } = router.query;
  const claimCaseNo = Number(claimNo);
  const policyNumber = Number(policyNo);
  const { logEvent } = useAmplitude();
  const { claim } = useClaim(claimCaseNo, !!claimCaseNo);
  const { policy } = usePolicy(policyNumber, !!policyNumber);

  let pathWithoutQueryParams = router.asPath.split('?')[0];

  if (app?.isApp) {
    return null;
  }

  const getHrefFromPath = (path: string) => {
    return (
      pathWithoutQueryParams.substring(
        0,
        pathWithoutQueryParams.indexOf(path)
      ) + path
    );
  };

  const getDynamicPathText = (path) => {
    if (requestId && path === requestId.toString()) {
      return t('breadcrumb.custom.offer');
    }

    if (claimNo && path === claimNo.toString()) {
      return claim?.causeTypeDescription || '';
    }

    if (policyNumber && path === policyNo.toString()) {
      return policy?.metadata.name || '';
    }

    return undefined;
  };

  const paths = pathWithoutQueryParams.split('/').filter((p) => p.length > 0);
  const pathsObjects: IBreadcrumbItem[] = paths.reduce<IBreadcrumbItem[]>(
    (acc, path) => {
      if (ignoredPaths.includes(path)) {
        return acc;
      }

      const pathText = getDynamicPathText(path);
      if (pathText !== undefined) {
        acc.push({ text: pathText, href: getHrefFromPath(path) });
      } else {
        acc.push({
          text: t(`breadcrumb.paths.${path}`, true, {}, { fallback: path }),
          href: getHrefFromPath(path),
        });
      }

      return acc;
    },
    []
  );

  // Hide breadcrumbs on path /
  if (pathsObjects.length < 1) {
    return <></>;
  }

  pathsObjects.unshift({
    text: t(`breadcrumb.paths.home`, true),
    icon: <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    href: '/',
  });

  const filteredPathObjects = pathsObjects.filter((po) => !!po.text);

  return (
    <Breadcrumbs className="u" sx={{ mt: 1, mb: 3 }} aria-label="breadcrumb">
      {filteredPathObjects.map(({ text, icon, href }, index) => {
        const activeRoute = filteredPathObjects.length === index + 1;

        return (
          <Link
            onClick={() =>
              logEvent(AnalyticsEvent.BREAD_CRUMB_CLICK, {
                page: router.pathname,
                href: href,
              })
            }
            href={href}
            key={text}
            variant="body1"
            underline="none"
            sx={{ display: 'flex', alignItems: 'center' }}
            color={activeRoute ? 'secondary.main' : 'secondary.light'}
          >
            {icon}
            {text}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default IconBreadcrumbs;
