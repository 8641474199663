import { Badge } from '@mui/material';
import WhiteIconButton from 'components/buttons/WhiteIconButton';
import * as React from 'react';
import OffersIcon from './offers-icon';

const BasketIcon = ({
  height = 24,
  width = 24,
  renderBadge = false,
}: {
  height?: number;
  width?: number;
  renderBadge?: boolean;
}) => (
  <WhiteIconButton
    sx={{
      width: 30,
      height: 30,
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Badge
      invisible={!renderBadge}
      color="warning"
      variant="dot"
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <OffersIcon height={height} width={width} />
    </Badge>
  </WhiteIconButton>
);

export default BasketIcon;
