export enum NativeAppMessage {
  DID_FINISH_LOADING = 'DID_FINISH_LOADING',
}

export function sendNativeAppMessage(message: NativeAppMessage, data?: any) {
  if (window && (window as any)?.webkit) {
    (window as any).webkit?.messageHandlers?.nativeApp?.postMessage({
      message,
      data,
    });
  }
}
