import { Icon, IconProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const WhiteIconButton = styled(Icon)<IconProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: grey[400],
  },
}));

export default WhiteIconButton;
