import * as React from 'react';

const FacebookIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0.720215C8.45637 0.720215 0.719971 8.45662 0.719971 18.0002C0.719971 27.5438 8.45637 35.2802 18 35.2802C27.5436 35.2802 35.28 27.5438 35.28 18.0002C35.28 8.45662 27.5436 0.720215 18 0.720215ZM22.0932 12.6614H19.4958C19.188 12.6614 18.846 13.0664 18.846 13.6046V15.4802H22.095L21.6036 18.155H18.846V26.1848H15.7806V18.155H12.9996V15.4802H15.7806V13.907C15.7806 11.6498 17.3466 9.81561 19.4958 9.81561H22.0932V12.6614Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
