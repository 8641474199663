import useSWR from 'swr';
import { Claim } from 'utility/node/claim/claim.model';
import { getClaimRoute } from 'utility/node/routes';

export const useClaim = (claimCaseNo: number, fetch = true) => {
  const { data, error, mutate } = useSWR<Claim>(
    fetch ? getClaimRoute(claimCaseNo) : null
  );

  return {
    claim: data,
    claimErrors: error,
    mutateClaim: mutate,
  };
};
