import useSWR, { Cache, mutate } from 'swr';
import { fetcherWithoutWrapper } from 'utility/api';
import { PolicyExtended } from 'utility/node/policy/policy.model';
import { getPolicyRoute } from 'utility/node/routes';
import { SentryGroup, addMessageWithGroup } from 'utility/sentry';

export const usePolicy = (policyNo: number, fetch = true) => {
  const policySwrKey = getPolicyRoute(policyNo);

  const { data, error, mutate } = useSWR<PolicyExtended>(
    fetch ? policySwrKey : null
  );

  return {
    policy: data,
    policyErrors: error,
    mutatePolicy: mutate,
  };
};

export const prefetchPolicy = (policyNo: number, cache: Cache<any>) => {
  const policySwrKey = getPolicyRoute(policyNo);

  if (!cache.get(policySwrKey)) {
    mutate(policySwrKey, fetcherWithoutWrapper(policySwrKey, {})).catch((e) => {
      addMessageWithGroup({
        group: SentryGroup.policyTransaction,
        tag: 'prefetchPolicy',
        message: e,
        printToConsole: true,
        consoleMessage: e,
      });
    });
  }
};
