import { Container, Grid, IconButton, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'next/legacy/image';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import FacebookIcon from '../icons/facebook-icon';
import LinkedinIcon from '../icons/linkedin-icon';
import InstagramIcon from '../icons/instagram-icon';
import { BrandContext } from 'pages/_app';
import { useContext } from 'react';
import { useTranslation } from 'hooks/support/useTranslation';

const Footer = () => {
  const brand = useContext(BrandContext);
  const { t } = useTranslation('footer');

  return (
    <Box
      sx={{
        ml: 3,
        pt: { xs: 2, xl: 5 },
        mr: -4,
        pr: 4,
        minHeight: { xl: 220 },
        backgroundImage: brand.details.media.footerIllustration
          ? `url(${brand.details.media.footerIllustration.imageUrl})`
          : 'none',
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          pt: 2,
        }}
      >
        <Grid className="u" container spacing={20} rowSpacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Image
              src={brand.details.media.logo.imageUrl}
              width={100}
              height={35}
              alt="company logo"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Typography variant="body2">{t('address')}</Typography>
            <Typography variant="body2">{t('cvr')}</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Box sx={{ display: 'flex' }}>
              <PhoneOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                <Link
                  color="secondary"
                  underline="none"
                  href={`tel:${t('phoneNumber')}`}
                >
                  {t('phoneNumber')}
                </Link>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <MailOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                <Link
                  color="secondary"
                  underline="none"
                  href={`mailto:${t('email')}`}
                >
                  {t('email')}
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={12} xl={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 150,
              }}
            >
              {brand.details.social.linkedin && (
                <IconButton
                  href={brand.details.social.linkedin}
                  target="_blank"
                >
                  <LinkedinIcon height={36} width={36} />
                </IconButton>
              )}
              {brand.details.social.facebook && (
                <IconButton
                  href={brand.details.social.facebook}
                  target="_blank"
                >
                  <FacebookIcon height={36} width={36} />
                </IconButton>
              )}
              {brand.details.social.instagram && (
                <IconButton
                  href={brand.details.social.instagram}
                  target="_blank"
                >
                  <InstagramIcon height={36} width={36} />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
