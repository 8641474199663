import {
  AppBar,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import styled from '@emotion/styled';
import SwipeableDrawerMenu from './swipeable-drawer-menu';
import DrawContent from './drawer-content';
import { SxProps } from '@mui/material';
import Link from '../link/link';
import {
  getConsentDetailsRoute,
  getContactDetailsRoute,
  getPersonalDetailsRoute,
  Route,
} from 'utility/routes';
import { AppContext, BrandContext, desktopMenuWidth } from 'pages/_app';
import { useContext } from 'react';
import { BrandSpecificContent } from 'components/specific-content/brand-specific-content';
import { Brands } from 'utility/brands';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useSession } from 'next-auth/react';
import Avatar from 'components/avatar/avatar';
import { isCustomerCommercial } from 'utility/customerTypes';
import { logout } from 'utility/auth/auth';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'hooks/support/useTranslation';
import BasketIcon from 'components/icons/basket-icon';
import { usePolicies } from 'hooks/api/usePolicies';
import { Status } from 'utility/node/tia-rest/model/response/policy.response.model';

const TopBar = ({ extended }: { extended: boolean; sx?: SxProps<Theme> }) => {
  const brand = useContext(BrandContext);
  const { t } = useTranslation('common');
  const StyledLogo = styled.img`
    height: 36px;
    max-width: 100%;
    padding: 0 16px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  `;
  const { data: session } = useSession();
  const { policies } = usePolicies();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logEvent } = useAmplitude();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const basketNotification = policies?.some(
    (policy) => policy.status === Status.QUOTE
  );

  const app = useContext(AppContext);
  const isApp = app?.isApp;

  return React.useMemo(() => {
    return (
      <Box
        sx={[
          (theme) => ({
            height: extended ? 372 : isApp ? 0 : 72,
            ml: { xs: 0, md: `${desktopMenuWidth}px` },
            background: `linear-gradient(99.72deg, ${theme.palette.primary.main} 15.96%, ${theme.palette.primary.light} 145.59%)`,
          }),
        ]}
      >
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: 'transparent',
          }}
          position="static"
          variant="elevation"
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ minHeight: isApp ? 0 : 72 }}>
              <Box
                sx={{
                  color: 'primary.contrastText',
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <SwipeableDrawerMenu badgeVisible={basketNotification} />
              </Box>
              <Box
                sx={{
                  color: 'primary.contrastText',
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <Box
                  width={270}
                  height="100vh"
                  sx={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    bgcolor: 'background.default',
                    zIndex: 30,
                  }}
                >
                  <DrawContent />
                </Box>
              </Box>

              <Box sx={{ ml: 'auto', display: { xs: 'none', md: 'block' } }}>
                <Stack direction="row">
                  <Link
                    underline="none"
                    sx={{ display: 'flex' }}
                    href={'/basket'}
                  >
                    <Stack direction="row" alignItems="center" mt={1}>
                      <BasketIcon
                        height={20}
                        width={20}
                        renderBadge={basketNotification}
                      />
                      <Typography
                        sx={{ pl: 1, pr: 2 }}
                        variant="body2"
                        color="primary.contrastText"
                      >
                        {t('topMenu.basket')}
                      </Typography>
                    </Stack>
                  </Link>
                  <Stack direction="row" alignItems="center" mt={1}>
                    <Avatar
                      sx={{
                        backgroundColor: '#fff',
                        color: 'primary.main',
                        fontSize: 14,
                        width: 30,
                        height: 30,
                      }}
                      name={session?.user?.name || ''}
                    />
                    <Button
                      variant="text"
                      sx={{
                        color: 'primary.contrastText',
                        textTransform: 'none',
                      }}
                      onClick={handleClick}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                          variant="body2"
                          color="primary.contrastText"
                        >
                          {session?.user?.name || ''}
                        </Typography>
                        {!!anchorEl ? <ExpandLess /> : <ExpandMore />}
                      </Stack>
                    </Button>
                  </Stack>
                </Stack>
                <Menu
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClick={handleClose}
                  onClose={handleClose}
                  hideBackdrop={true}
                  className="u"
                  sx={{ minWidth: !!anchorEl ? anchorEl.clientWidth : '100%' }}
                >
                  <Link
                    color="text.primary"
                    underline="none"
                    onClick={handleClose}
                    href={getPersonalDetailsRoute()}
                  >
                    <MenuItem sx={{ py: 1 }}>
                      {t('profileMenu.personalDetails', true)}
                    </MenuItem>
                  </Link>
                  <Link
                    color="text.primary"
                    underline="none"
                    onClick={handleClose}
                    href={getContactDetailsRoute()}
                  >
                    <MenuItem sx={{ py: 1 }}>
                      {t('profileMenu.contactDetails')}
                    </MenuItem>
                  </Link>
                  <Link
                    sx={{
                      display: isCustomerCommercial(session) ? 'none' : 'block',
                    }}
                    color="text.primary"
                    underline="none"
                    onClick={handleClose}
                    href={getConsentDetailsRoute()}
                  >
                    <MenuItem sx={{ py: 1 }}>
                      {t('profileMenu.consents')}
                    </MenuItem>
                  </Link>
                  {/* {isBrowser() && browserSupportsWebAuthn() && (
                    <Link
                      color="text.primary"
                      underline="none"
                      onClick={handleClose}
                      href={getWebauthDetailsRoute()}
                    >
                      <MenuItem sx={{ py: 1 }}>
                        {t('profileMenu.webauth')}
                      </MenuItem>
                    </Link>
                  )} */}
                  <Divider sx={{ mt: 1.5, mb: 1 }} />
                  <MenuItem
                    sx={{ py: 1 }}
                    onClick={() =>
                      logout(logEvent, brand.details.links.logoutRedirectUrl)
                    }
                  >
                    {t('profileMenu.logout')}
                  </MenuItem>
                </Menu>
              </Box>
              <Box
                sx={{ display: { xs: isApp ? 'none' : 'block', md: 'none' } }}
              >
                <BrandSpecificContent onlyFor={[Brands.DFHF]}>
                  <Box
                    sx={{
                      position: 'absolute',
                      right: { xs: 'auto', md: -16 },
                      left: { xs: -15, md: 'auto' },
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <Link href={Route.DASHBOARD}>
                      <StyledLogo
                        style={{ filter: 'brightness(0) invert(1)' }}
                        src={brand.details.media.logo.imageUrl}
                      />
                    </Link>
                  </Box>
                </BrandSpecificContent>
                <BrandSpecificContent notFor={[Brands.DFHF]}>
                  <Box
                    sx={{
                      width: 137,
                      height: 56,
                      backgroundColor: 'background.default',
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      position: 'absolute',
                      right: { xs: 'auto', md: 0 },
                      left: { xs: 0, md: 'auto' },
                      top: 0,
                    }}
                  >
                    <Link href={Route.DASHBOARD}>
                      <StyledLogo src={brand.details.media.logo.imageUrl} />
                    </Link>
                  </Box>
                </BrandSpecificContent>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }, [basketNotification, extended, anchorEl]);
};
export default TopBar;
