import { Brands } from 'utility/brands';
import { BrandContext } from 'pages/_app';
import { PropsWithChildren, useContext } from 'react';
import { includes } from 'lodash';

export const BrandSpecificContent = ({
  onlyFor,
  notFor,
  children,
}: PropsWithChildren<{
  onlyFor?: Brands[];
  notFor?: Brands[];
}>) => {
  const brandContext = useContext(BrandContext);
  if (onlyFor) {
    if (!includes(onlyFor, brandContext?.details?.brandAbbreviation)) {
      return null;
    }
  }

  if (notFor) {
    if (includes(notFor, brandContext?.details?.brandAbbreviation)) {
      return null;
    }
  }

  return <>{children}</>;
};
