import { RequestInit } from 'next/dist/server/web/spec-extension/request';
import { CustomError } from './logging/custom-error';
import { ApiRoute } from './node/routes';
import { addMessageWithGroup, SentryGroup } from './sentry';

const resToJSON = async (res: Response) => {
  const jsonBody = JSON.parse(await res.text(), (key: any, value: any) => {
    const isDate =
      typeof value === 'string' &&
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.exec(
        value
      );
    return isDate ? new Date(value) : value;
  });
  return jsonBody;
};

export const fetcherWithWrapper = async (
  resource: RequestInfo,
  init: globalThis.RequestInit
) => {
  const res = await fetch(resource, init);
  if (!res.ok) {
    const error = new CustomError('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return resToJSON(res);
};

export const fetcherWithoutWrapper = async (
  resource: RequestInfo,
  init: globalThis.RequestInit
) => {
  const res = await fetch(resource, init);
  if (!res.ok) {
    const error = new CustomError('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return (await resToJSON(res)).content;
};

export const postFormDataRequest = async (
  resource: string | ApiRoute,
  body: FormData,
  options?: RequestInit
) => {
  try {
    const res = await fetch(resource, {
      method: 'POST',
      headers: {},
      body: body,
      ...options,
    });

    if (!res.ok) {
      const error = new CustomError(
        'An error occurred while fetching the data.'
      );
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return await resToJSON(res);
  } catch (ex) {
    const message = `Unable to post request ${resource} ${ex}`;
    addMessageWithGroup({
      message: message,
      group: SentryGroup.common,
      printToConsole: true,
      consoleMessage: message,
    });
    return undefined;
  }
};
export const postRequest = async (
  resource: string | ApiRoute,
  body: any,
  options?: RequestInit
) => {
  try {
    const res = await fetch(resource, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      ...options,
    });

    if (!res.ok) {
      const error = new CustomError(
        'An error occurred while fetching the data.'
      );
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return await resToJSON(res);
  } catch (ex) {
    const message = `Unable to post request ${resource} ${ex}`;
    addMessageWithGroup({
      message: message,
      group: SentryGroup.common,
      printToConsole: true,
      consoleMessage: message,
    });
    return undefined;
  }
};
