import { Session } from 'next-auth/core/types';
import { JWT } from 'next-auth/jwt';

export enum CustomerTypes {
  PRIVATE = 'PRIVATE',
  COMMERCIAL = 'COMMERCIAL',
}

export const getCustomerTypeFromSession = (
  session: JWT | Session
): CustomerTypes => {
  return session?.user?.customerType || CustomerTypes.PRIVATE;
};

export const isCustomerPrivate = (session: JWT | Session): boolean =>
  getCustomerTypeFromSession(session) === CustomerTypes.PRIVATE;

export const isCustomerCommercial = (session: JWT | Session): boolean =>
  getCustomerTypeFromSession(session) === CustomerTypes.COMMERCIAL;
