import * as React from 'react';

const ContactIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 15.7642H16C15.04 15.7642 14.25 14.9742 14.25 14.0142V9.01416C14.25 8.05416 15.04 7.26416 16 7.26416H17C18.52 7.26416 19.75 8.49416 19.75 10.0142V13.0142C19.75 14.5342 18.52 15.7642 17 15.7642ZM16 8.76416C15.86 8.76416 15.75 8.87416 15.75 9.01416V14.0142C15.75 14.1542 15.86 14.2642 16 14.2642H17C17.69 14.2642 18.25 13.7042 18.25 13.0142V10.0142C18.25 9.32416 17.69 8.76416 17 8.76416H16Z"
      fill="currentColor"
    />
    <path
      d="M4 15.7642H3C1.48 15.7642 0.25 14.5342 0.25 13.0142V10.0142C0.25 8.49416 1.48 7.26416 3 7.26416H4C4.96 7.26416 5.75 8.05416 5.75 9.01416V14.0142C5.75 14.9742 4.96 15.7642 4 15.7642ZM3 8.76416C2.31 8.76416 1.75 9.32416 1.75 10.0142V13.0142C1.75 13.7042 2.31 14.2642 3 14.2642H4C4.14 14.2642 4.25 14.1542 4.25 14.0142V9.01416C4.25 8.87416 4.14 8.76416 4 8.76416H3Z"
      fill="currentColor"
    />
    <path
      d="M16.5 8.76416C16.09 8.76416 15.75 8.42416 15.75 8.01416V7.51416C15.75 4.34416 13.17 1.76416 10 1.76416C6.83 1.76416 4.25 4.34416 4.25 7.51416V8.01416C4.25 8.42416 3.91 8.76416 3.5 8.76416C3.09 8.76416 2.75 8.42416 2.75 8.01416V7.51416C2.75 3.51416 6 0.26416 10 0.26416C14 0.26416 17.25 3.51416 17.25 7.51416V8.01416C17.25 8.42416 16.91 8.76416 16.5 8.76416Z"
      fill="currentColor"
    />
    <path
      d="M10.6201 20.0142H9.37012C8.27012 20.0142 7.37012 19.1142 7.37012 18.0142C7.37012 16.9142 8.27012 16.0142 9.37012 16.0142H10.6201C11.7201 16.0142 12.6201 16.9142 12.6201 18.0142C12.6201 19.1142 11.7201 20.0142 10.6201 20.0142ZM9.38012 17.5142C9.10012 17.5142 8.88012 17.7342 8.88012 18.0142C8.88012 18.2942 9.10012 18.5142 9.38012 18.5142H10.6301C10.9101 18.5142 11.1301 18.2942 11.1301 18.0142C11.1301 17.7342 10.9101 17.5142 10.6301 17.5142H9.38012Z"
      fill="currentColor"
    />
    <path
      d="M13.9999 18.7642H11.8799C11.4699 18.7642 11.1299 18.4242 11.1299 18.0142C11.1299 17.6042 11.4699 17.2642 11.8799 17.2642H13.9999C14.6899 17.2642 15.2499 16.7042 15.2499 16.0142V15.0142C15.2499 14.6042 15.5899 14.2642 15.9999 14.2642C16.4099 14.2642 16.7499 14.6042 16.7499 15.0142V16.0142C16.7499 17.5342 15.5199 18.7642 13.9999 18.7642Z"
      fill="currentColor"
    />
  </svg>
);

export default ContactIcon;
