import { createTheme } from '@mui/material/styles';
import { Theme as EmotionTheme } from '@emotion/react';
import { Theme as MuiTheme } from '@mui/material';

const palette: MuiTheme = createTheme({
  palette: {
    text: {
      primary: '#1C4320',
      secondary: '#04330F',
      tertiary: '#3C3C3C',
      light: '#95B447',
    },
    mode: 'light',
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#1C4220',
      contrastText: '#f6f5ef',
      dark: '#C5E76D',
      light: '#04330F',
      ultraLight: '#EDEDE3',
    },
    secondary: {
      main: '#000000',
      light: '#575757',
      dark: '#E0E0E0',
    },
    info: {
      main: '#01579B',
      light: '#EAE7F2',
      contrastText: '#39287C',
    },
    success: {
      main: '#C5E86C',
      light: '#E8F6C5',
      contrastText: '#1C4320',
      ultraLight: '#2e7d321f',
    },
    warning: {
      main: '#C2900C',
      light: '#F6F5EF',
      contrastText: '#1C4320',
    },
  },
});

// Create a theme instance.
const theme: MuiTheme = createTheme({
  ...palette,
  typography: {
    h1: { fontSize: 96, fontWeight: 500 },
    h2: { fontSize: 60, fontWeight: 400 },
    h3: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: '56px',
      '@media (max-width:1199px)': {
        fontSize: 28,
        lineHeight: '42px',
      },
    },
    h4: {
      fontSize: 34,
      fontWeight: 600,
      lineHeight: '42px',
      '@media (max-width:1199px)': {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
    h5: {
      fontSize: 29,
      lineHeight: '38px',
      fontWeight: 500,
      '@media (max-width:1199px)': {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    h6: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 600,
      '@media (max-width:1199px)': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    overline: { fontSize: 12, fontWeight: 400 },
    caption: { fontSize: 12, fontWeight: 400 },
    subtitle1: { fontSize: 16, fontWeight: 700 },
    subtitle2: { fontSize: 14, fontWeight: 700 },
    body1: { fontSize: 16, fontWeight: 500 },
    body2: { fontSize: 14, fontWeight: 500, color: '#04330F' },
    fontFamily: [
      'Quicksand',
      'Roboto',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
        containedPrimary: {
          color: palette.palette.success.main,
          '&:hover': {
            backgroundColor: '#2F6340',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorWarning: {
          backgroundColor: '#C6E76D',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          color: palette.palette.warning.main,
          backgroundColor: palette.palette.warning.light,
        },

        filledError: {
          color: palette.palette.error.contrastText,
          backgroundColor: palette.palette.error.light,
        },
        filledSuccess: {
          color: palette.palette.success.contrastText,
          backgroundColor: palette.palette.success.light,
        },
        filledInfo: {
          color: palette.palette.info.main,
          backgroundColor: palette.palette.info.light,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(232, 246, 196, 0.4)',
            color: '#1C4220',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#ffffff',
          borderRadius: 20,
          boxShadow: '0px 0px 5px 0px rgba(141, 141, 141, 0.13)',
          '&:hover': {
            boxShadow:
              '0px 14px 55px rgba(0, 0, 0, 0.08), 0px 5px 17px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.03)',
          },
          '.MuiCardActionArea-focusHighlight': {
            background: 'transparent',
          },
          '.MuiCardHeader-root': {
            background: 'transparent',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#3C3C3C',
        },
      },
    },
  },
});

const emotionTheme: EmotionTheme = {
  ...theme,
};

export default emotionTheme;
