import { Callback } from 'amplitude-js';
import { signIn, signOut } from 'next-auth/react';
import { AnalyticsEvent } from '../analytics/events';
import * as Sentry from '@sentry/nextjs';
import { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';
import { startAuthentication } from '@simplewebauthn/browser';
import { Route } from 'utility/routes';

export const login = async (
  logEvent: <T extends string>(
    eventType: T,
    eventPropertiesIn?: object,
    callback?: Callback | undefined
  ) => void,
  includeSSN: boolean
) => {
  let scopes = process.env.NEXT_PUBLIC_MITID_SCOPES;

  if (includeSSN) {
    scopes = scopes + ' ssn';
  }
  logEvent(AnalyticsEvent.LOGIN);
  return await signIn('mitid', {}, { scope: scopes });
};

export const logout = async (
  logEvent: <T extends string>(
    eventType: T,
    eventPropertiesIn?: object,
    callback?: Callback | undefined
  ) => void,
  logoutUrl: string
) => {
  logEvent(AnalyticsEvent.LOGOUT);
  Sentry.configureScope((scope) => scope.setUser(null));

  return signOut({
    callbackUrl: logoutUrl,
    redirect: true,
  });
};

export const signInWithWebauthn = async (
  localSessionId: string,
  bypassSignIn?: boolean
) => {
  const url = new URL(Route.WEBAUTH_AUTHENTICATE, window.location.origin);
  url.searchParams.set('sessionId', localSessionId);
  const optionsResponse = await fetch(url.toString());

  if (optionsResponse.status !== 200) {
    throw new Error('Could not get authentication options from server');
  }
  const opt: PublicKeyCredentialRequestOptionsJSON =
    await optionsResponse.json();

  if (!opt.allowCredentials || opt.allowCredentials.length === 0) {
    throw new Error('There is no registered credential.');
  }

  const credential = await startAuthentication({ ...opt });

  if (bypassSignIn) {
    return opt;
  }

  await signIn('credentials', {
    id: credential.id,
    rawId: credential.rawId,
    type: credential.type,
    clientDataJSON: credential.response.clientDataJSON,
    authenticatorData: credential.response.authenticatorData,
    signature: credential.response.signature,
    userHandle: credential.response.userHandle,
    sessionId: localSessionId,
  });
};
