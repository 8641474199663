import create from 'zustand';

export interface ContainerStore {
  notificationsHeight: number;
  setNotificationsHeight: (height: number) => void;
}

export const useContainerStore = create<ContainerStore>((set, get) => ({
  notificationsHeight: 0,
  setNotificationsHeight: (height) => set({ notificationsHeight: height }),
}));
