import styled from '@emotion/styled';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  Box,
  Typography,
} from '@mui/material';
import { PortableText } from '@portabletext/react';
import Link from '../link/link';
import { PortableTextComponents } from '../portable-text-components/portableTextComponents';
import Card, { ICard } from './card';
import ArrowForwardFilled from '@mui/icons-material/ArrowForward';
import { CmsCard as ICmsCard } from 'utility/node/sanity/cms-card';

const StyledUl = styled.ul`
  padding-inline-start: 24px;
`;

const StyledOl = styled.ol`
  padding-inline-start: 24px;
`;

const StyledLi = styled.li`
  list-style-image: none;
  &::marker {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: bold;
  }
`;

const portableTextComponents = {
  ...PortableTextComponents,
  ...{
    listItem: {
      ...PortableTextComponents.listItem,
      bullet: ({ children }) => (
        <ListItem sx={{ my: 1.5, pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 42 }}>
            <ArrowForwardFilled color="primary" />
          </ListItemIcon>
          <div>{children}</div>
        </ListItem>
      ),
    },
    list: {
      ...PortableTextComponents.list,
      bullet: ({ children }) => <List>{children}</List>,
    },
  },
};

const CmsCard = ({
  cmsCard,
  onClick,
  showContent = true,
  mediaSize = 'medium',
  slug,
}: {
  cmsCard: ICmsCard;
  onClick?: () => void;
  showContent?: boolean;
  mediaSize?: 'medium' | 'large';
  slug: string;
}) => {
  const cmsCardElement: ICard = {
    elementKey: `cmsCard-${slug}`,
    cardMedia: {
      size: mediaSize,
      alt: cmsCard.media.alt,
      image: cmsCard.media.imageUrl,
    },
    showContent: showContent,
    onClick: onClick,
    header: {
      title: cmsCard.title,
      centerTitle: showContent ? false : true,
    },
    content: cmsCard.modalContent
      ? {
          showDivider: true,
          content: (
            <Box sx={{ py: 2 }}>
              <PortableText
                components={portableTextComponents}
                value={cmsCard.modalContent}
              />
            </Box>
          ),
        }
      : null,
    actions: cmsCard.actions?.map((action) => {
      if (cmsCard.modalContent && !showContent) {
        return (
          <Typography
            key={action._key}
            sx={{ fontSize: 13 }}
            variant="button"
            color="primary"
          >
            {action.text}
          </Typography>
        );
      }

      return (
        <Link
          sx={{ ml: 'auto' }}
          href={action.link}
          key={action._key}
          underline="none"
          target="_blank"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Button size="small" variant="text" key="2">
            {action.text}
          </Button>
        </Link>
      );
    }),
    cardType: 'cms',
    logObject: {
      cmsCardSlug: slug,
    },
  };

  return <Card {...cmsCardElement} />;
};

export default CmsCard;
