import dateFormat, { i18n } from 'dateformat';

export const getShortDate = (date: Date | string) => {
  if (!date) {
    return '';
  }
  let d: Date = typeof date === 'string' ? new Date(date) : date;

  const yyyy = d.getFullYear();
  let mm: string | number = d.getMonth() + 1; // Months start at 0!
  let dd: string | number = d.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '.' + mm + '.' + yyyy;
};

export const partialSsn = (date: Date | string) => {
  if (!date) {
    return '';
  }
  let d: Date = typeof date === 'string' ? new Date(date) : date;

  const yy = d.getFullYear()?.toString().slice(2, 4);
  let mm: string | number = d.getMonth() + 1; // Months start at 0!
  let dd: string | number = d.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return `${dd}${mm}${yy}`;
};

export const getExpressiveDateString = (date: Date | string) => {
  if (!date) {
    return '';
  }
  let d: Date = typeof date === 'string' ? new Date(date) : date;
  return dateFormat(d, "dddd 'd.' d mmmm yyyy");
};

export const getShortDateWithTime = (
  date: Date | string,
  includeSeconds: boolean = false
) => {
  let d: Date = typeof date === 'string' ? new Date(date) : date;

  const dateFormatted = getShortDate(date);
  if (d.getHours() === 0 && d.getMinutes() === 0 && d.getSeconds() === 0)
    return dateFormatted;

  let hh: string | number = d.getHours();
  let mm: string | number = d.getMinutes();
  let ss: string | number = d.getSeconds();

  if (hh < 10) hh = '0' + hh;
  if (mm < 10) mm = '0' + mm;
  if (ss < 10) ss = '0' + ss;

  return dateFormatted + ' ' + hh + ':' + mm + (includeSeconds ? ':' + ss : '');
};

export const getIsoLocalDate = (date: Date | string) => {
  let d: Date = typeof date === 'string' ? new Date(date) : date;

  const offset = d.getTimezoneOffset();
  d = new Date(d.getTime() - offset * 60 * 1000);
  return d.toISOString().split('T')[0];
};

export const calculateAge = (birthday: Date) => {
  if (!birthday) {
    return undefined;
  }
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

i18n.dayNames = [
  'Søn',
  'Man',
  'Tir',
  'Ons',
  'Tor',
  'Fre',
  'Lør',
  'Søndag',
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
];

i18n.monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december',
];

i18n.timeNames = ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'];

export const removeTime = (date = new Date()) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const getBirthDateFromCpr = (cpr: string) => {
  const cprShortYear = parseInt(cpr.substring(4, 6));
  const cprMonth = parseInt(cpr.substring(2, 4)) - 1;
  const cprDay = parseInt(cpr.substring(0, 2));
  const timeZoneOffset = new Date().getTimezoneOffset();
  const getDate = (century: number) => {
    const date = new Date(century + cprShortYear, cprMonth, cprDay);
    date.setHours(0, date.getMinutes() - timeZoneOffset, 0, 0);
    return date;
  };

  const cprLastFour = parseInt(cpr.substring(7, 11));
  if (cprLastFour <= 3999) {
    return getDate(1900);
  }

  if (cprLastFour <= 4999) {
    if (cprShortYear <= 36) {
      return getDate(2000);
    }
    return getDate(1900);
  }

  if (cprLastFour <= 8999) {
    if (cprShortYear <= 57) {
      return getDate(2000);
    }
    return getDate(1800);
  }

  if (cprLastFour <= 9999) {
    if (cprShortYear <= 36) {
      return getDate(2000);
    }
    return getDate(1900);
  }

  return undefined;
};
