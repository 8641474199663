import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { BrandContext } from 'pages/_app';
import { useContext } from 'react';
import Link from '../link/link';

const StyledUl = styled.ul`
  padding-inline-start: 24px;
`;

const StyledOl = styled.ol`
  padding-inline-start: 24px;
`;

const StyledLi = styled.li`
  &::marker {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: bold;
  }
`;

export const PortableTextComponents: any = {
  listItem: {
    bullet: ({ children }) => (
      <StyledLi>
        <div>{children}</div>
      </StyledLi>
    ),
    number: ({ children }) => (
      <StyledLi>
        <div>{children}</div>
      </StyledLi>
    ),
  },
  list: {
    bullet: ({ children }) => <StyledUl>{children}</StyledUl>,
    number: ({ children }) => <StyledOl>{children}</StyledOl>,
  },
  block: {
    h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
    h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
    h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
    h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
    h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
    h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
    caption: ({ children }) => (
      <Typography variant="caption">{children}</Typography>
    ),
    subtitle1: ({ children }) => (
      <Typography variant="subtitle1">{children}</Typography>
    ),
    subtitle2: ({ children }) => (
      <Typography variant="subtitle2">{children}</Typography>
    ),
    normal: ({ children }) => (
      <Typography sx={{ pt: '0.5rem', pb: '0.75rem' }} variant="body1">
        {children}
      </Typography>
    ),
    body1: ({ children }) => (
      <Typography sx={{ pt: '0.5rem', pb: '0.75rem' }} variant="body1">
        {children}
      </Typography>
    ),
    body2: ({ children }) => (
      <Typography sx={{ pt: '0.5rem', pb: '0.75rem' }} variant="body2">
        {children}
      </Typography>
    ),
  },
  types: {},

  marks: {
    color: ({ value, children }) => {
      const { palette, color } = value;
      const StyledColor = styled.span`
        color: ${(props) => props.theme.palette[palette][color]};
      `;

      return <StyledColor>{children}</StyledColor>;
    },
    variables: ({ children, value }) => {
      return <TranslationComponent variableName={value.variableName} />;
    },
    link: ({ children, value }) => {
      const { newTab, href } = value;
      let target = newTab ? '_blank' : undefined;
      const rel = !href.startsWith('/') ? 'noreferrer noopener' : undefined;
      return (
        <Link underline="none" target={target} href={value.href} rel={rel}>
          {children}
        </Link>
      );
    },
  },
};

const TranslationComponent = ({ variableName }: { variableName: string }) => {
  const brand = useContext(BrandContext);
  const value = brand?.details?.translations[variableName] || '';

  return <>{value}</>;
};
