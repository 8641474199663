import { Backdrop, Fade } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import CmsCard from '../card/cms-card';
import CloseFilled from '@mui/icons-material/Close';
import { CmsCard as ICmsCard } from 'utility/node/sanity/cms-card';

const CmsCardDetailsModal = ({
  open,
  cmsData,
  onClose,
  slug,
}: {
  open: boolean;
  cmsData: ICmsCard;
  onClose: () => any;
  slug: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: '90%',
            height: 'auto',
            maxWidth: 655,
            position: 'absolute',
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box position="relative">
            <Box
              position="absolute"
              right={16}
              top={12}
              onClick={onClose}
              sx={{ cursor: 'pointer', zIndex: 1 }}
            >
              <CloseFilled fontSize="medium" sx={{ color: '#ffffff' }} />
            </Box>
            <CmsCard slug={slug} cmsCard={cmsData} mediaSize="large" />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CmsCardDetailsModal;
