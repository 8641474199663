import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
  IconButton,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DrawContent from './drawer-content';
import BasketIcon from 'components/icons/basket-icon';
import { AppContext } from 'pages/_app';

const SwipeableDrawerMenu = ({ badgeVisible }: { badgeVisible: boolean }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isApp = useContext(AppContext)?.isApp;

  if (isApp) {
    return null;
  }
  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  const list = () => (
    <Box onClick={toggleDrawer()} onKeyDown={toggleDrawer()}>
      <Stack>
        <Box sx={{ textAlign: 'end', float: 'right', mt: 2, mr: 2 }}>
          <IconButton aria-label="close" onClick={toggleDrawer()}>
            <CloseIcon sx={{ color: '#000' }} />
          </IconButton>
        </Box>
        <DrawContent />
      </Stack>
    </Box>
  );

  return (
    <>
      <Stack
        sx={{
          position: 'absolute',
          right: '0',
          top: '12px',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Link sx={{ display: 'flex' }} href={'/basket'}>
          <BasketIcon height={20} width={20} renderBadge={badgeVisible} />
        </Link>
        <IconButton
          size="large"
          aria-label="close menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={toggleDrawer()}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
      </Stack>
      <SwipeableDrawer
        anchor={'right'}
        open={isMobile ? open : false}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
};

export default SwipeableDrawerMenu;
