module.exports = {
  locales: ['da'],
  defaultLocale: 'da',
  pages: {
    '*': ['common', 'footer', 'automatic-insurance', 'policy'],
    '/': ['home', 'claim', 'policy', 'quote'],
    '/app': ['app', 'quote', 'home'],
    '/auth/error': ['auth/error'],
    '/404': ['404'],
    '/500': ['500'],
    '/profile': [
      'profile/index',
      'profile/contact',
      'profile/consent',
      'profile/about-you',
      'profile/webauth',
    ],
    '/file-a-claim': ['file-a-claim'],
    '/basket/checkout': ['basket/checkout/index'],
    '/basket/checkout/success': ['basket/checkout/success'],
    '/basket/checkout/manual-process': [
      'basket/checkout/manual-process',
      'basket/index',
    ],
    '/insurances': ['insurances', 'policy'],
    '/insurances/[policyNo]': ['insurances', 'policy'],
    '/contact': ['contact'],
    '/claims': ['claims/index', 'claim'],
    '/claims/[claimNo]': ['claims/details'],
    '/file-claim/success/[claimNo]': ['file-claim/success'],
    '/file-claim': ['file-claim/index'],
    '/faq': ['faq'],
    '/buy-insurances': ['buy-insurances/index', 'policy', 'bundle-discount'],
    '/buy-insurances/wizard': ['buy-insurances/wizard'],
    '/basket': [
      'basket/index',
      'basket/offer-details',
      'bundle-discount',
      'quote',
    ],
  },
  interpolation: {
    prefix: '${',
    suffix: '}',
  },
};
