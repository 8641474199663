import {
  Backdrop,
  Button,
  Card,
  CardContent,
  Fade,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import CloseFilled from '@mui/icons-material/Close';
import { useParty } from 'hooks/api/useParty';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'hooks/support/useTranslation';
import { postRequest } from 'utility/api';
import { ApiRoute } from 'utility/node/routes';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import ContactDoubleIcon from 'components/icons/contact-double';
import * as Sentry from '@sentry/nextjs';
import { useAmplitude } from 'react-amplitude-hooks';
import { AnalyticsEvent } from 'utility/analytics/events';

const UpdateContactModal = ({ show }: { show: boolean }) => {
  const { t } = useTranslation('common');
  const { party, mutateParty } = useParty();
  const [posting, setPosting] = useState(false);
  const [open, setOpen] = useState(show);
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent(AnalyticsEvent.PERIODICAL_CONTACT_DETAILS_MODAL_SHOWN);
  });

  const onClose = () => {
    logEvent(AnalyticsEvent.PERIODICAL_CONTACT_DETAILS_MODAL_CLOSED);
    postRequest(ApiRoute.PARTY_VERIFY_CONTACT, {}).catch((e) => {
      Sentry.captureException(e);
    });
    setOpen(false);
  };

  const phoneRegex =
    /^(00[0-9][0-9]|\+[0-9][0-9])?\ ?[2-9][0-9]\ ?[1-9][0-9]\ ?([0-9]\ ?){4}$/;

  const validationSchema = yup.object({
    email: yup.string().email(t('validations.invalidEmail')),
    phone: yup
      .string()
      .matches(phoneRegex, t('validations.invalidPhoneNumber')),
    mobile: yup
      .string()
      .matches(phoneRegex, t('validations.invalidPhoneNumber')),
  });

  const formik = useFormik({
    initialValues: {
      ...party,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      logEvent(AnalyticsEvent.PERIODICAL_CONTACT_DETAILS_MODAL_UPDATED);
      setPosting(true);
      await postRequest(ApiRoute.PARTY_ME, values);
      onClose();
      await mutateParty();
      setPosting(false);
    },
  });

  if (!party) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: '90%',
            height: 'auto',
            maxWidth: 655,
            position: 'absolute',
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&:focus-visible': { outline: 'none' },
          }}
        >
          <Box position="relative">
            <Box
              position="absolute"
              right={16}
              top={12}
              onClick={onClose}
              sx={{ cursor: 'pointer', zIndex: 1 }}
            >
              <CloseFilled fontSize="medium" />
            </Box>
            <Card sx={{ p: 0.5 }}>
              <CardContent
                sx={{
                  '&:last-child': {
                    pb: 2,
                  },
                  mt: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 3,
                    color: 'primary.main',
                  }}
                >
                  <ContactDoubleIcon width={74} height={60} />
                </Box>
                <Typography variant="h6">{t('updateContact.title')}</Typography>
                <Typography variant="body2" mt={1}>
                  {t('updateContact.description')}
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Stack mt={3} spacing={1.5}>
                    <TextField
                      name="phone"
                      fullWidth
                      label={t('updateContact.fields.phoneNumber')}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                      name="mobile"
                      fullWidth
                      label={t('updateContact.fields.mobile')}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                    <TextField
                      name="email"
                      fullWidth
                      label={t('updateContact.fields.email')}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Stack>
                  <Stack sx={{ mt: 2 }} direction="row">
                    <Button fullWidth variant="text" onClick={onClose}>
                      {t('updateContact.close')}
                    </Button>
                    <LoadingButton
                      disabled={posting}
                      fullWidth
                      size="small"
                      loading={posting}
                      type="submit"
                      variant="contained"
                    >
                      {t('updateContact.saveChanges')}
                    </LoadingButton>
                  </Stack>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateContactModal;
