import * as React from 'react';

const ContactDoubleIcon = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 74 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 8.5C57.4625 8.5 68.375 17.1625 68.375 27.825C68.375 34.5625 64 40.4875 57.3875 43.9375L59.5875 52.7625C59.6157 52.8727 59.6107 52.9887 59.5729 53.096C59.5352 53.2033 59.4665 53.297 59.3755 53.3652C59.2845 53.4335 59.1753 53.4732 59.0618 53.4794C58.9482 53.4856 58.8354 53.458 58.7375 53.4L47.75 46.875C46.5065 47.0366 45.254 47.1201 44 47.125C30.5375 47.125 19.625 38.4875 19.625 27.825C19.625 17.1625 30.5375 8.5 44 8.5Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5 30C44.8807 30 46 28.8807 46 27.5C46 26.1193 44.8807 25 43.5 25C42.1193 25 41 26.1193 41 27.5C41 28.8807 42.1193 30 43.5 30Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5 30C53.8807 30 55 28.8807 55 27.5C55 26.1193 53.8807 25 52.5 25C51.1193 25 50 26.1193 50 27.5C50 28.8807 51.1193 30 52.5 30Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 30C35.8807 30 37 28.8807 37 27.5C37 26.1193 35.8807 25 34.5 25C33.1193 25 32 26.1193 32 27.5C32 28.8807 33.1193 30 34.5 30Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3526 10.4925C26.7225 10.1704 25.0223 10 23.278 10C11.6637 10 2 17.5566 2 27.1333C2 33.0253 5.69746 38.1593 11.2118 41.2172L9.46495 48.2821L9.46437 48.2844C9.41088 48.4967 9.42077 48.7201 9.49291 48.9269C9.56532 49.1345 9.69743 49.3165 9.87335 49.4495C10.0493 49.5826 10.2611 49.6604 10.4819 49.6726C10.7027 49.6847 10.9217 49.6305 11.1113 49.5173L11.1119 49.5169L20.2364 44.0539C21.2448 44.1774 22.2596 44.2413 23.2756 44.2453L23.278 44.2453C24.5807 44.2453 25.8589 44.1505 27.1017 43.9686C26.4835 43.6384 25.885 43.2869 25.3078 42.9153C24.6415 42.9682 23.965 42.9953 23.2795 42.9953C22.2437 42.9912 21.2091 42.9216 20.1818 42.787C20.0422 42.7687 19.9004 42.7981 19.7796 42.8705L10.7546 48.274L12.5415 41.0474C12.6105 40.7683 12.4802 40.4781 12.2257 40.3442C6.77251 37.4758 3.25 32.5998 3.25 27.1333C3.25 18.4933 12.0787 11.25 23.278 11.25C24.3763 11.25 25.4517 11.3197 26.4983 11.4535C27.0972 11.1122 27.7158 10.7915 28.3526 10.4925Z"
      fill="currentColor"
    />
  </svg>
);

export default ContactDoubleIcon;
