import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LockOutlined } from '@mui/icons-material';
import CookieIcon from '../icons/cookie-icon';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { IConsentCookie } from 'utility/cookies/consent-cookie.type';
import { ConsentType } from 'utility/cookies/cookie-consent-types';
import { useTranslation } from 'hooks/support/useTranslation';
import { isBrowser } from 'utility/common';
import { useCookie } from 'react-use';
import { createPortal } from 'react-dom';

const CookieSections: {
  default: boolean;
  mandatory: boolean;
  type: ConsentType;
}[] = [
  {
    type: ConsentType.NECESSARY,
    default: true,
    mandatory: true,
  },
  {
    type: ConsentType.STATISTIC,
    default: false,
    mandatory: false,
  },
];

const CookieConsent = ({}) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [value, updateCookie] = useCookie('cookie_consent');
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  const defaultState: { [key: string]: boolean } = {};
  CookieSections.forEach(
    (section) =>
      (defaultState[section.type] = section.default || section.mandatory)
  );

  const [selectedCookieCategories, setSelectedCookieCategories] = useState<{
    [key: string]: boolean;
  }>(defaultState);
  const [showDetails, setShowDetails] = useState(false);

  if (value) {
    const consentCookie: IConsentCookie | undefined = JSON.parse(value);
    const allowClarityCookies = consentCookie?.consents_approved.includes(
      ConsentType.STATISTIC
    );

    if (allowClarityCookies && (window as any).clarity) {
      (window as any).clarity('consent');
    }

    if (consentCookie?.consents_approved.includes(ConsentType.STATISTIC)) {
      (window as any).ppms.cm.api(
        'setComplianceSettings',
        {
          consents: {
            analytics: {
              status: 1,
            },
          },
        },
        function onSuccess() {},
        function onError() {}
      );
    }
    return <></>;
  } else if (isBrowser()) {
    const windowConst = window ? (window as any) : undefined;
    windowConst?.ppms.cm.api(
      'setInitialComplianceSettings',
      {
        consents: ['analytics'],
      },
      function onSuccess() {},
      function onError() {}
    );
  }

  const handleAcceptAll = () => {
    const acceptedCookieTypes = CookieSections.map((section) => section.type);
    setConsentCookie(acceptedCookieTypes);
  };

  const handleAcceptMandatory = () => {
    const acceptedCookieTypes = CookieSections.filter((c) => c.mandatory).map(
      (section) => section.type
    );
    setConsentCookie(acceptedCookieTypes);
  };

  const handleAcceptSelected = () => {
    const acceptedCookieTypes = CookieSections.filter(
      (c) => selectedCookieCategories[c.type]
    ).map((section) => section.type);
    setConsentCookie(acceptedCookieTypes);
  };

  const setConsentCookie = (sectionKeys: string[]) => {
    const cookieContent: IConsentCookie = {
      consents_approved: sectionKeys,
      consents_denied: CookieSections.filter(
        (c) => !sectionKeys.includes(c.type)
      ).map((c) => c.type),
      timestamp: new Date(),
      path: router.pathname,
    };

    updateCookie(JSON.stringify(cookieContent), {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  const mandatorySwitch = (
    <Box
      sx={{
        ml: 'auto',
        width: 47,
        height: 24,
        borderRadius: 20,
        backgroundColor: '#F3F4F6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LockOutlined sx={{ m: 'auto', fontSize: 18 }} />
    </Box>
  );

  const detailsContent = (
    <Box>
      <Button
        startIcon={<ChevronLeftIcon />}
        onClick={() => setShowDetails(false)}
        color="inherit"
      >
        Tilbage
      </Button>
      <Typography variant="h5" sx={{ my: 1 }}>
        Du bestemmer over din data
      </Typography>
      <Typography variant="body2">
        Vi og vores samarbejdspartnere bruger teknologier, herunder cookies, til
        at indsamle oplysninger om dig til forskellige formål, herunder:
      </Typography>
      <Typography variant="subtitle2">
        <ol>
          <li>Nødvendige</li>
          <li>Statistik</li>
        </ol>
      </Typography>
      <Typography variant="body2">
        Ved at trykke på &apos;Accepter alle&apos; giver du samtykke til alle
        disse formål. Du kan også vælge at tilkendegive, hvilke formål du vil
        give samtykke til ved at benytte [checkboksene] ud for formålet, og
        derefter trykke på &apos;{t(`cookieConsent.actions.acceptSelected`)}
        &apos;.
      </Typography>
      <Typography variant="h5" sx={{ my: 1 }}>
        Om cookies
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Hvad er en cookie?
      </Typography>
      <Typography variant="body2">
        En cookie er en lille datafil, som bliver gemt på din computer, tablet
        eller mobiltelefon. En cookie er ikke et program, der kan indeholde
        skadelige programmer eller virus.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Hjemmesidens brug af cookies
      </Typography>
      <Typography variant="body2">
        Cookies kan være nødvendige for at få hjemmesiden til at fungere.
        Cookies hjælper os desuden med at få overblik over dit besøg på
        hjemmesiden, så vi løbende kan optimere og målrette hjemmesiden til dine
        behov og interesser. Cookies husker f.eks., hvad du har lagt i en
        eventuel indkøbskurv, om du tidligere har besøgt siden, om du er logget
        ind og hvilket sprog og valuta, du gerne vil have vist på hjemmesiden.
        Vi bruger også cookies til at målrette vores annoncer over for dig på
        andre hjemmesider. Helt overordnet anvender vi cookies som del af vores
        service til at vise indhold, der er så relevant som muligt for dig.
      </Typography>
      <Typography variant="body2">
        Du kan se, hvilke services der sætter cookies, og til hvilke formål
        under de forskellige kategorier [Nødvendige, Funktionelle, Statistiske
        og Marketing].
      </Typography>

      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Hvor længe bliver cookies gemt?
      </Typography>
      <Typography variant="body2">
        Det varierer, hvor længe en bestemt cookie bliver opbevaret på dine
        enheder og browsere. En cookies levetid beregnes ud fra dit seneste
        besøg på hjemmesiden. Når cookiens levetid udløber, bliver den
        automatisk slettet. Alle vores cookies&apos; levetid fremgår i vores
        cookiepolitik.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Sådan afviser eller sletter du cookies
      </Typography>
      <Typography variant="body2">
        Du kan altid afvise alle -/tredjepartscookies fuldstændig ved at ændre
        indstillingerne i din browser på din computer, tablet eller telefon.
        Hvor du finder indstillingerne afhænger af, hvilken browser du anvender.
        Du skal dog være opmærksom på, at hvis du afviser alle
        -/tredjepartscookies, vil der være funktioner og services, du ikke kan
        anvende på hjemmesiden (fordi disse er afhængige af cookies).
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Ændring af dit samtykke
      </Typography>
      <Typography variant="body2">
        Du ændrer dit samtykke ved enten at slette cookies fra browseren eller
        ved at ændre dit oprindelige valg ved at klikke på linket herunder:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle2">
        Har du spørgsmål?
      </Typography>
      <Typography variant="body2">
        Har du kommentarer eller spørgsmål i forbindelse med vores information
        og/eller behandling af personlige oplysninger, er du velkommen til at
        kontakte os. Selve cookiepolitikken bliver opdateret hver måned af
        Cookie Information. Har du har spørgsmål til cookiepolitikken, kan du
        kontakte Cookie Information via deres hjemmeside ved at klikke her
      </Typography>
    </Box>
  );

  const cookeConsentAcceptContent = (
    <>
      <Box sx={{ mt: 2 }} />
      <Box sx={{ m: 'auto', width: 'fit-content' }}>
        <Typography color="primary" sx={{ mt: -2, ml: -2 }}>
          <CookieIcon width={50} height={50} />
        </Typography>
      </Box>
      <Typography sx={{ my: 1 }} textAlign="center" variant="h4">
        {t(`cookieConsent.title`)}
      </Typography>
      <Typography sx={{ my: 2 }} variant="body2">
        {t(`cookieConsent.subTitle`)}
        <Link
          sx={{ cursor: 'pointer' }}
          href={'/'}
          onClick={() => setShowDetails(true)}
        >
          {t(`cookieConsent.readMoreAction`)}
        </Link>
      </Typography>
      <Stack sx={{ mt: 2 }} spacing={2}>
        {CookieSections.map((section, index) => (
          <Accordion
            key={index}
            sx={{
              boxShadow:
                '0px 1px 4px 0px rgba(0, 0, 0, 0.03), 0px 5px 17px 0px rgba(0, 0, 0, 0.05), 0px 14px 55px 0px rgba(0, 0, 0, 0.08)',
            }}
          >
            <AccordionSummary
              sx={{ flexDirection: 'row-reverse', height: 62 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography sx={{ ml: 2, my: 'auto' }} variant="subtitle2">
                {t(`cookieConsent.categories.${section.type}.title`)}
              </Typography>
              <Box sx={{ ml: 'auto' }}>
                {section.mandatory ? (
                  mandatorySwitch
                ) : (
                  <Switch
                    checked={selectedCookieCategories[section.type]}
                    onChange={(e) =>
                      setSelectedCookieCategories({
                        ...selectedCookieCategories,
                        [section.type]: e.target.checked,
                      })
                    }
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                {t(`cookieConsent.categories.${section.type}.content`)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
      <Grid container spacing={1} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Button
            fullWidth={true}
            variant="contained"
            onClick={() => handleAcceptAll()}
          >
            {t(`cookieConsent.actions.acceptAll`)}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={handleAcceptSelected}
          >
            {t(`cookieConsent.actions.acceptSelected`)}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={handleAcceptMandatory}
          >
            {t(`cookieConsent.actions.acceptMandatory`)}
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const body = (
    <Box
      sx={{ maxWidth: 630, p: 1, overflowY: 'auto', overflowX: 'hidden' }}
      position="fixed"
      left="0"
      bottom="0"
      maxHeight="100%"
      zIndex={40}
    >
      <Card>
        <CardContent>
          {showDetails ? detailsContent : cookeConsentAcceptContent}
        </CardContent>
      </Card>
    </Box>
  );

  return mounted && ref.current ? createPortal(body, document.body) : null;
};

export default CookieConsent;
