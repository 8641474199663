import * as Sentry from '@sentry/nextjs';

export const enum SentryGroup {
  policyTransaction = 'policyTransaction',
  nextWay = 'nextWay',
  saftyRest = 'saftyRest',
  tiaParty = 'tiaParty',
  tiaPolicy = 'tiaPolicy',
  tiaClaim = 'tiaClaim',
  tiaCommon = 'tiaCommon',
  tiaIntegration = 'tiaIntegration',
  tiaCase = 'tiaCase',
  sjf = 'sjf',
  userManagement = 'userManagement',
  tiaAccount = 'tiaAccount',
  auth = 'authorization',
  document = 'document',
  buyInsurance = 'buy-insurance',
  common = 'common',
  sentry = 'sentry',
  acceptOffer = 'acceptOffer',
  basket = 'basket',
  mobile = 'mobile',
  risk = 'risk',
  data = 'data',
  claim = 'claim',
  faq = 'faq',
  profile = 'profile',
  sanity = 'sanity',
  consent = 'consent',
  validation = 'validation',
}

export interface SentryMessageOptions {
  group: SentryGroup;
  message?: string;
  tag?: string;
  level?: Sentry.SeverityLevel;
  printToConsole?: boolean;
  consoleMessage?: string | [];
}

export const addMessageWithGroup = ({
  message,
  group,
  tag,
  level = 'error',
  printToConsole = false,
  consoleMessage,
}: SentryMessageOptions) => {
  Sentry.configureScope((scope) => {
    if (tag) {
      scope.setTag('tag', tag);
    }
    scope.setTransactionName(group);
    scope.setFingerprint([group]);

    Sentry.captureMessage(message, level);
  });
  if (printToConsole) {
    printMessageByLevel(consoleMessage, level);
  }
};

const printMessageByLevel = (
  message: string | [],
  level: Sentry.SeverityLevel
) => {
  const messageString = Array.isArray(message) ? message.join('\n') : message;
  if (level === 'error') {
    console.error(messageString);
  } else {
    console.log(messageString);
  }
};
