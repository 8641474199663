import { getIsoLocalDate } from '../date';

export enum ApiRoute {
  PARTY_ME = '/api/party/me',
  PARTY_VERIFY_CONTACT = '/api/party/contactVerified',
  PARTY_CONSENTS = '/api/party/consents',
  DEV = '/api/auth/test',
  POLICIES = '/api/policy/me',
  POLICY = '/api/policy/',
  POLICY_BY_ID = '/api/policy/policyId/:policyId',
  CLAIMS = '/api/claim/me',
  CLAIM = '/api/claim/',
  CLAIM_DOCUMENT = '/api/claim/documents/',
  CLAIM_DOCUMENT_FILE = '/api/claim/documents/:claimNo/file?id=:documentId',
  CLAIM_DOCUMENT_FILE_UPLOAD = '/api/claim/documents/:claimNo/upload',
  CLAIM_TIMELINE = '/api/claim/timeline/',
  DOCUMENTS_FILE = '/api/documents/file',
  OPEN_QUOTES_ANSWERS = '/api/openQuotes/answers',
  OPEN_QUOTES_QUESTIONS = '/api/openQuotes/questions',
  FILE_CLAIM_OPTIONS_CAUSES = '/api/claim/fileClaim/options/causes/:policyId/:eventId',
  FILE_CLAIM_OPTIONS_EVENTS = '/api/claim/fileClaim/options/events/:policyId',
  FILE_CLAIM_QUESTIONS_QUESTIONNAIRE = '/api/claim/fileClaim/questionnaire/:policyId/:eventId/:causeId',
  FILE_CLAIM_QUESTIONS_THIRDPARTIES = '/api/claim/fileClaim/thirdparties/:policyId/:eventId/:causeId',
  FILE_CLAIM = '/api/claim/fileClaim',
  UP_SALE_RISK = '/api/policy/upsale/risk',
  RECOMMENDATIONS_FEEDBACK = '/api/recommendations/feedback',
  RECOMMENDATIONS_FOR_PRODUCT = '/api/recommendations/:productLineId',
  BASKET = '/api/basket',
  BASKET_BUY = '/api/basket/buy',
  QUOTE_EDIT = '/api/quote/edit/:policyNo',
  CAR_MAKE_INTEGRATION = '/api/integrations/car-makes',
  CAR_MODEL_INTEGRATION = '/api/integrations/car-models',
  MOTORCYCLE_INTEGRATION = '/api/integrations/motorcycles',
  CHANGE_CANCEL_CODE_ON_QUOTE = '/api/basket/change-cancel-code',
  BUNDLE_DISCOUNT_ADDITIONAL_DISCOUNT = '/api/basket/additional-bundle-discount-policies',
  UPDATE_BUNDLE_DISCOUNT = '/api/basket/update-bundle-discount',
  DMR_INTEGRATION = '/api/integrations/dmr',
  BBR_INTEGRATION = '/api/integrations/bbr',
}

export const getRecommendationForProductRoute = (productLineId: string) =>
  ApiRoute.RECOMMENDATIONS_FOR_PRODUCT.replace(':productLineId', productLineId);

export const getQuoteEdit = (policyNo: string) =>
  ApiRoute.QUOTE_EDIT.replace(':policyNo', policyNo);

export const getFileClaimQuestionsQuestionnaire = (
  policyId: number,
  eventId: string,
  causeId: string,
  lastQuestionId: string
) =>
  ApiRoute.FILE_CLAIM_QUESTIONS_QUESTIONNAIRE.replace(
    ':policyId',
    policyId.toString()
  )
    .replace(':eventId', eventId)
    .replace(':causeId', causeId) + `?lastQuestionId=${lastQuestionId}`;

export const getFileClaimThirdPartyQuestions = (
  policyId: number,
  eventId: string,
  causeId: string,
  lastQuestionId: string
) =>
  ApiRoute.FILE_CLAIM_QUESTIONS_THIRDPARTIES.replace(
    ':policyId',
    policyId.toString()
  )
    .replace(':eventId', eventId)
    .replace(':causeId', causeId) + `?lastQuestionId=${lastQuestionId}`;

export const getFileClaimCauseOptions = (
  policyId: number,
  eventId: string,
  objectId: number
) =>
  ApiRoute.FILE_CLAIM_OPTIONS_CAUSES.replace(
    ':policyId',
    policyId.toString()
  ).replace(':eventId', eventId) + `?objectId=${objectId}`;

export const getFileClaimEventOptions = (policyId: number, objectId: number) =>
  ApiRoute.FILE_CLAIM_OPTIONS_EVENTS.replace(':policyId', policyId.toString()) +
  `?objectId=${objectId}`;

export const getPoliciesRoute = (date?: Date) => {
  if (!date) {
    return ApiRoute.POLICIES;
  }

  return `${ApiRoute.POLICIES}?date=${getIsoLocalDate(date)}`;
};

export const getPolicyRoute = (policyNo: number) =>
  `${ApiRoute.POLICY}${policyNo}`;

export const getPolicyByIdRoute = (policyId: number) =>
  ApiRoute.POLICY_BY_ID.replace(':policyId', policyId.toString() || '');

export const getClaimRoute = (claimNo: number) => `${ApiRoute.CLAIM}${claimNo}`;

export const getClaimDocumentsRoute = (claimNo: number) =>
  `${ApiRoute.CLAIM_DOCUMENT}${claimNo}`;

export const getClaimDocumentFileRoute = (
  claimNo: number,
  documentId: string
) =>
  ApiRoute.CLAIM_DOCUMENT_FILE.replace(':claimNo', claimNo.toString()).replace(
    ':documentId',
    encodeURIComponent(documentId)
  );

export const getPostClaimDocumentRoute = (claimNo: number) =>
  ApiRoute.CLAIM_DOCUMENT_FILE_UPLOAD.replace(':claimNo', claimNo.toString());

export const getClaimTimelineRoute = (claimCaseNo: number) =>
  `${ApiRoute.CLAIM_TIMELINE}${claimCaseNo}`;

export const getDocumentFileRoute = (
  attachmentId: number,
  archiveId: number,
  fileName: string
) =>
  `${ApiRoute.DOCUMENTS_FILE}?attachmentId=${attachmentId}&archiveId=${archiveId}&fileName=${fileName}`;
