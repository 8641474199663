import { createTheme } from '@mui/material/styles';
import { Theme as EmotionTheme } from '@emotion/react';
import { Theme as MuiTheme } from '@mui/material';
import DefaultTheme from './default-theme';
// Create a theme instance.

const fontFamily = [
  'Open Sans',
  'Roboto',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const palette: MuiTheme = createTheme({
  palette: {
    ...DefaultTheme.palette,
    text: {
      primary: '#3C3C3C',
      secondary: '#3C3C3C',
      tertiary: '#3C3C3C',
      light: '#5a92c4',
    },
    mode: 'light',
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#005094',
      contrastText: '#ffffff',
      dark: '#313944',
      light: '#5a92c4',
      ultraLight: '#EDEFF2',
    },
    secondary: {
      main: '#2F80B2',
      light: '#3698D3',
      dark: '#E0E0E0',
    },
    info: {
      main: '#01579B',
      light: '#EDEFF2',
      contrastText: '#005094',
    },
    success: {
      main: '#66bb6a',
      light: '#E0EDD3',
      contrastText: '#14884D',
      ultraLight: '#2e7d321f',
    },
    warning: {
      main: '#DF651E',
      light: '#FFE0B2',
      contrastText: '#DF651E',
    },
  },
});
const theme: MuiTheme = createTheme({
  ...DefaultTheme,
  ...palette,
  typography: {
    ...DefaultTheme.typography,
    h1: { ...DefaultTheme.typography.h1, fontFamily: fontFamily },
    h2: { ...DefaultTheme.typography.h2, fontFamily: fontFamily },
    h3: { ...DefaultTheme.typography.h3, fontFamily: fontFamily },
    h4: { ...DefaultTheme.typography.h4, fontFamily: fontFamily },
    h5: { ...DefaultTheme.typography.h5, fontFamily: fontFamily },
    h6: { ...DefaultTheme.typography.h6, fontFamily: fontFamily },
    overline: { ...DefaultTheme.typography.overline, fontFamily: fontFamily },
    caption: { ...DefaultTheme.typography.caption, fontFamily: fontFamily },
    subtitle1: { ...DefaultTheme.typography.subtitle1, fontFamily: fontFamily },
    subtitle2: { ...DefaultTheme.typography.subtitle2, fontFamily: fontFamily },
    body1: { ...DefaultTheme.typography.body1, fontFamily: fontFamily },
    body2: { ...DefaultTheme.typography.body2, fontFamily: fontFamily },
  },
  components: {
    ...DefaultTheme.components,
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          color: palette.palette.warning.main,
          backgroundColor: palette.palette.warning.light,
        },

        filledError: {
          color: palette.palette.error.contrastText,
          backgroundColor: palette.palette.error.light,
        },
        filledSuccess: {
          color: palette.palette.success.contrastText,
          backgroundColor: palette.palette.success.light,
        },
        filledInfo: {
          color: palette.palette.info.main,
          backgroundColor: palette.palette.info.light,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(54, 152, 211, 0.08)',
            color: '#005094',
          },
        },
      },
    },
  },
});

const emotionTheme: EmotionTheme = {
  ...theme,
};

export default emotionTheme;
