import { Box, CircularProgress, SxProps } from '@mui/material';

const LoadingComponent = ({
  fullPage,
  sx,
  size,
  color = 'primary',
}: {
  fullPage?: boolean;
  sx?: SxProps;
  size?: string | number;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
}) => (
  <Box sx={{ my: 4, textAlign: 'center', ...sx }}>
    <CircularProgress size={size} color={color} />
  </Box>
);

export default LoadingComponent;
