export const textTemplate = (template: string, obj: object) => {
  if (!template) {
    return '';
  }
  if (!obj) {
    return template;
  }

  var keys = Object.keys(obj);
  return template.replace(/{([^{}]+)}/g, function (keyExpr, key) {
    return obj[key] || '';
  });
};

export const randomString = (length = 6) => {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const isStringUuid = (uuid: string) => {
  return uuid.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};
