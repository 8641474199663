import { useMemo } from 'react';
import useSWR from 'swr';
import { PolicySlim } from 'utility/node/policy/policy.model';
import { getPoliciesRoute } from 'utility/node/routes';
import { Status } from 'utility/node/tia-rest/model/response/policy.response.model';

const activeQuoteStatuses = [Status.QUOTE, Status.SUSPENDED_QUOTE];
const canceledQuoteStatuses = [Status.CANCELED_QUOTE];
const activePolicyStatuses = [
  Status.ACTIVE,
  Status.LATER_IN_FORCE,
  Status.AWAITING_INPUT,
];
const expiredPolicyStatuses = [Status.EXPIRED];

const defaults: Pick<PoliciesOptions, 'fetch' | 'revalidateOnMount'> = {
  fetch: true,
  revalidateOnMount: true,
};

interface PoliciesOptions {
  fetch?: boolean;
  revalidateOnMount?: boolean;
}
export const usePolicies = (coverDate?: Date, options?: PoliciesOptions) => {
  const _options = { ...defaults, ...options };
  const { fetch, revalidateOnMount } = _options;
  const { data, error, mutate } = useSWR<PolicySlim[]>(
    fetch ? getPoliciesRoute(coverDate) : null,
    { revalidateOnMount: revalidateOnMount }
  );

  const {
    quotes,
    firstQuoteToExpire,
    activePolicies,
    expiredPolicies,
    canceledQuotes,
  } = useMemo(() => {
    const quotes = data?.filter((p) => activeQuoteStatuses.includes(p.status));
    let firstQuoteToExpire: PolicySlim | null = null;
    const sortedQuotes = quotes?.sort((a, b) => {
      if (!b.quoteExpirationInDays) {
        return -1;
      }
      return a.quoteExpirationInDays - b.quoteExpirationInDays;
    });

    if (!!sortedQuotes && sortedQuotes.length > 0) {
      firstQuoteToExpire = sortedQuotes[0];
    }

    const activePolicies = data?.filter((p) =>
      activePolicyStatuses.includes(p.status)
    );
    const expiredPolicies = data?.filter((p) =>
      expiredPolicyStatuses.includes(p.status)
    );
    const canceledQuotes = data?.filter((p) =>
      canceledQuoteStatuses.includes(p.status)
    );

    return {
      quotes,
      firstQuoteToExpire,
      activePolicies,
      expiredPolicies,
      canceledQuotes,
    };
  }, [data]);

  return {
    policies: data,
    activePolicies,
    expiredPolicies,
    activeQuotes: quotes,
    canceledQuotes,
    policiesErrors: error,
    mutatePolicies: mutate,
    policiesLoading: !data && !error && fetch,
    firstQuoteToExpire: firstQuoteToExpire,
  };
};
