import * as React from 'react';

const FaqIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.56 15.7741C17.42 15.7741 17.28 15.7341 17.15 15.6541C16.8 15.4341 16.7 14.9641 16.93 14.6141C18.69 11.8941 18.69 8.38412 16.93 5.66412C16.71 5.31412 16.81 4.85412 17.15 4.62412C17.5 4.40412 17.96 4.50412 18.19 4.84412C20.27 8.05412 20.27 12.2041 18.19 15.4241C18.05 15.6441 17.81 15.7641 17.56 15.7641V15.7741Z"
      fill="currentColor"
    />
    <path
      d="M9.99998 15.9041C8.51998 15.9041 7.04998 15.3441 5.91998 14.2141C4.82998 13.1241 4.22998 11.6741 4.22998 10.1341C4.22998 8.59414 4.82998 7.14414 5.91998 6.05414C7.00998 4.96414 8.45998 4.36414 9.99998 4.36414C11.54 4.36414 12.99 4.96414 14.08 6.05414C16.33 8.30414 16.33 11.9641 14.08 14.2141C12.96 15.3341 11.48 15.9041 9.99998 15.9041ZM9.99998 5.87414C8.85998 5.87414 7.78998 6.31414 6.97998 7.12414C6.16998 7.93414 5.72998 9.00414 5.72998 10.1441C5.72998 11.2841 6.16998 12.3541 6.97998 13.1641C8.63998 14.8241 11.35 14.8241 13.01 13.1641C14.67 11.5041 14.67 8.79414 13.01 7.13414C12.2 6.32414 11.13 5.88414 9.98998 5.88414L9.99998 5.87414Z"
      fill="currentColor"
    />
    <path
      d="M14.45 8.57413C14.23 8.57413 14.02 8.48413 13.87 8.30413C13.61 7.98413 13.65 7.51413 13.97 7.24413L17.11 4.66413C17.19 4.60413 17.2 4.52413 17.2 4.48413C17.2 4.44413 17.2 4.36413 17.13 4.29413L15.86 3.02413C15.79 2.95413 15.72 2.95413 15.67 2.95413C15.63 2.95413 15.55 2.97413 15.49 3.04413L12.91 6.18413C12.65 6.50413 12.17 6.55413 11.85 6.28413C11.53 6.02413 11.48 5.54413 11.75 5.22413L14.33 2.08413C14.64 1.70413 15.1 1.47413 15.6 1.44413C16.09 1.41413 16.58 1.60413 16.92 1.95413L18.19 3.22413C18.54 3.57413 18.73 4.05413 18.7 4.54413C18.68 5.03413 18.44 5.49413 18.06 5.81413L14.92 8.39413C14.78 8.50413 14.61 8.56413 14.44 8.56413L14.45 8.57413Z"
      fill="currentColor"
    />
    <path
      d="M4.31996 18.8442C3.85996 18.8442 3.40996 18.6642 3.07996 18.3342L1.80996 17.0642C1.45996 16.7142 1.26996 16.2342 1.29996 15.7442C1.31996 15.2542 1.55996 14.7842 1.93996 14.4742L5.07996 11.8942C5.39996 11.6342 5.86996 11.6742 6.13996 11.9942C6.39996 12.3142 6.35996 12.7842 6.03996 13.0542L2.89996 15.6342C2.81996 15.6942 2.80996 15.7742 2.80996 15.8142C2.80996 15.8542 2.80996 15.9342 2.87996 16.0042L4.14996 17.2742C4.21996 17.3442 4.29996 17.3442 4.33996 17.3442C4.37996 17.3442 4.45996 17.3242 4.51996 17.2542L7.09996 14.1142C7.35996 13.7942 7.83996 13.7442 8.15996 14.0142C8.47996 14.2742 8.52996 14.7542 8.25996 15.0742L5.67996 18.2142C5.36996 18.5942 4.90996 18.8242 4.40996 18.8542C4.37996 18.8542 4.34996 18.8542 4.31996 18.8542V18.8442Z"
      fill="currentColor"
    />
    <path
      d="M5.55008 8.57413C5.38008 8.57413 5.21008 8.51413 5.07008 8.40413L1.93008 5.82413C1.55008 5.51413 1.32008 5.05413 1.29008 4.55413C1.27008 4.06413 1.45008 3.57413 1.80008 3.22413L3.07008 1.95413C3.42008 1.60413 3.90008 1.41413 4.39008 1.44413C4.88008 1.46413 5.35008 1.70413 5.66008 2.08413L8.24008 5.22413C8.50008 5.54413 8.46008 6.01413 8.14008 6.28413C7.82008 6.54413 7.35008 6.50413 7.08008 6.18413L4.50008 3.04413C4.44008 2.96413 4.36008 2.95413 4.32008 2.95413C4.28008 2.95413 4.20008 2.95413 4.13008 3.02413L2.86008 4.29413C2.79008 4.36413 2.78008 4.44413 2.79008 4.48413C2.80008 4.52413 2.81008 4.60413 2.88008 4.66413L6.02008 7.24413C6.34008 7.50413 6.39008 7.98413 6.12008 8.30413C5.97008 8.48413 5.76008 8.57413 5.54008 8.57413H5.55008Z"
      fill="currentColor"
    />
    <path
      d="M15.68 18.8542C15.68 18.8542 15.62 18.8542 15.6 18.8542C15.11 18.8342 14.64 18.5942 14.33 18.2142L11.75 15.0742C11.49 14.7542 11.53 14.2842 11.85 14.0142C12.17 13.7542 12.64 13.7942 12.91 14.1142L15.49 17.2542C15.55 17.3342 15.63 17.3442 15.67 17.3442C15.71 17.3442 15.79 17.3442 15.86 17.2742L17.13 16.0042C17.2 15.9342 17.21 15.8542 17.2 15.8142C17.2 15.7742 17.18 15.6942 17.11 15.6342L13.97 13.0542C13.65 12.7942 13.6 12.3142 13.87 11.9942C14.13 11.6742 14.61 11.6242 14.93 11.8942L18.07 14.4742C18.45 14.7842 18.68 15.2442 18.71 15.7442C18.73 16.2342 18.55 16.7242 18.2 17.0642L16.93 18.3342C16.6 18.6642 16.15 18.8442 15.69 18.8442L15.68 18.8542Z"
      fill="currentColor"
    />
    <path
      d="M10.0001 19.8942C8.16006 19.8942 6.32006 19.3742 4.71006 18.3342C4.36006 18.1042 4.26006 17.6442 4.49006 17.2942C4.72006 16.9442 5.18006 16.8442 5.53006 17.0742C8.25006 18.8342 11.7601 18.8342 14.4801 17.0742C14.8301 16.8542 15.2901 16.9542 15.5201 17.2942C15.7401 17.6442 15.6401 18.1042 15.3001 18.3342C13.6901 19.3742 11.8501 19.8942 10.0101 19.8942H10.0001Z"
      fill="currentColor"
    />
    <path
      d="M2.44 15.7742C2.19 15.7742 1.95 15.6542 1.81 15.4342C-0.27 12.2242 -0.27 8.07422 1.81 4.85422C2.03 4.50422 2.5 4.40422 2.85 4.63422C3.2 4.85422 3.3 5.32422 3.07 5.67422C1.31 8.39422 1.31 11.9042 3.07 14.6242C3.29 14.9742 3.19 15.4342 2.85 15.6642C2.72 15.7442 2.58 15.7842 2.44 15.7842V15.7742Z"
      fill="currentColor"
    />
    <path
      d="M14.88 3.33417C14.74 3.33417 14.6 3.29417 14.47 3.21417C11.75 1.45417 8.23998 1.45417 5.51998 3.21417C5.16998 3.43417 4.70998 3.33416 4.47998 2.99416C4.24998 2.64417 4.35998 2.18417 4.69998 1.95416C7.90998 -0.125835 12.07 -0.125835 15.28 1.95416C15.63 2.18417 15.73 2.64417 15.5 2.99416C15.36 3.21416 15.12 3.33417 14.87 3.33417H14.88Z"
      fill="currentColor"
    />
  </svg>
);

export default FaqIcon;
