import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { PropsWithChildren } from 'react';
import { useContainerStore } from 'stores/container-store';

interface AppContainerProps {
  disableFooter: boolean;
  extendedHeader: boolean;
  noContainerPadding: boolean;
}

const AppContainer = ({
  disableFooter,
  extendedHeader,
  noContainerPadding,
  children,
}: PropsWithChildren<AppContainerProps>) => {
  const { notificationsHeight } = useContainerStore((state) => ({
    notificationsHeight: state.notificationsHeight,
  }));

  return (
    <Container
      maxWidth="xl"
      id="content-container"
      sx={{
        pt: 2,
        px: { xs: noContainerPadding ? 0 : 3 },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          minHeight: disableFooter
            ? `calc(100vh - 161px - ${notificationsHeight}px)`
            : extendedHeader
            ? {
                xs: `calc(100vh - 292px - 52px - ${notificationsHeight}px)`,
                xl: `calc(100vh - 292px - ${notificationsHeight}px)`,
              }
            : {
                xs: `calc(100vh - 277px - 95px - ${notificationsHeight}px)`,
                xl: `calc(100vh - 293px - 64px - ${notificationsHeight}px)`,
              },
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default AppContainer;
