import { Translate } from 'hooks/support/useTranslation';
import { ITiaParty } from './node/tia-rest/model/response/party.response.model';

export const isBrowser = () => typeof window !== 'undefined';

export const constructAddressFromParty = (party: ITiaParty) => {
  if (!party) {
    return '';
  }

  const { city, postArea, street, streetNo, floor, floorExtension } = party;

  return constructAddress(
    city,
    postArea,
    street,
    streetNo,
    floor,
    floorExtension
  );
};
export const constructAddress = (
  city: string,
  postArea: string,
  street: string,
  streetNo: string | number,
  floor?: string,
  floorExtension?: string
) => {
  let floorString = '';
  if (floor || floorExtension) {
    if (floor && floorExtension) {
      floorString = ` ${floor} ${floorExtension},`;
    } else {
      if (floor) {
        floorString = ` ${floor},`;
      }

      if (floorExtension) {
        floorString = ` ${floorExtension},`;
      }
    }
  }

  return `${street || ''} ${streetNo || ''},${floorString || ''} ${
    postArea || ''
  } ${city || ''}`;
};

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const formatPrice = (
  price: number | string,
  addDecimals: boolean = true
): string => {
  const numPrice = Number(price);
  if (!numPrice) return '';
  let formattedPrice = price.toString();

  if (addDecimals) {
    formattedPrice = numPrice.toFixed(2);
  }
  return formattedPrice.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatRiskExcess = (
  riskExcessDesc: string | number,
  t: Translate
): string => {
  if (!riskExcessDesc) {
    return '';
  }

  const trimmedRiskExcessDesc = removeDuplicateSpaces(
    riskExcessDesc.toString()
  );

  if (trimmedRiskExcessDesc.indexOf('Ingen selvrisiko') > -1) {
    return trimmedRiskExcessDesc;
  }

  if (!trimmedRiskExcessDesc.match(/[0-9]*$/)[0]) {
    return `${t('excess')} ${trimmedRiskExcessDesc}`; //Risk excess desc does not include a number
  }

  if (trimmedRiskExcessDesc.startsWith('kr.')) {
    if (trimmedRiskExcessDesc.replaceAll('.', '').match(/[0-9]*$/)[0] === '0') {
      return t('noExcess'); //Risk number is '0'
    }

    const swappedPrice = trimmedRiskExcessDesc.replace('kr.', '') + ' kr.';
    return removeDuplicateSpaces(`${t('excess')} ${swappedPrice}`); //Risk excess desc starts with 'kr.' and is not '0', we move kr. to the end of the string
  }

  return `${t('excess')} ${trimmedRiskExcessDesc}`; //We don't know what to do, so we return it
};

const removeDuplicateSpaces = (str: string): string =>
  str.replace(/\s\s+/g, ' ');

export const softBreakLongWords = (
  text: string | undefined,
  length: number
) => {
  if (!text) {
    return text;
  }
  const regex = new RegExp(`[A-Za-z0-9_\/]{${length},}`, 'g');

  return text.replace(regex, function (m) {
    return m.match(/.{1,10}/g).join('\u00AD');
  });
};

export const formatRiskSum = (
  sum: string | number,
  riskNo: number,
  productLineId: string
) => {
  if (productLineId === 'M10' && riskNo === 601) {
    if (Number(sum) > 1) {
      return `${sum} børn`;
    } else {
      return `${sum} barn`;
    }
  }
  if (!sum) {
    return '';
  }
  const strSum = sum.toString();

  //Remove kroner, kr, spaces, dots and commas
  const cleanSum = (input: string) =>
    input
      .replace('kroner', '')
      .replace('max', '')
      .replace('pr. dag', '')
      .replace(/[kr\.]{2,3}/gi, '')
      .replace(/[\s,.]/g, '');

  const isPerDay = strSum.includes('pr. dag');

  let resultSum: string | undefined = undefined;

  if (strSum.includes(' - ')) {
    const sumArray = strSum.split(' - ');
    const cleanedSumArray = sumArray.map((strSum) => cleanSum(strSum));

    resultSum = `${formatPrice(
      Number(cleanedSumArray[0]),
      false
    )} - ${formatPrice(Number(cleanedSumArray[1]), false)}`;
  } else {
    const cleanedSum = cleanSum(strSum);
    if (Number(cleanedSum)) {
      resultSum = formatPrice(Number(cleanedSum), false);
    }
  }

  if (!resultSum) {
    return undefined;
  }

  return `${resultSum} kr.${isPerDay ? ' pr. dag' : ''}`;
};

export const scrollToElementByName = (fieldsWithErrors: string[]) => {
  if (fieldsWithErrors.length > 0) {
    const els = document.getElementsByName(fieldsWithErrors[0]);
    const el = Array.from(els).find((e) => e.clientHeight > 0);

    if (el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1);
    }
  }
};
