import * as React from 'react';

const InsuranceIcon = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.8042C9.59 2.8042 9.25 2.4642 9.25 2.0542V1.0542C9.25 0.644199 9.59 0.304199 10 0.304199C10.41 0.304199 10.75 0.644199 10.75 1.0542V2.0542C10.75 2.4642 10.41 2.8042 10 2.8042Z"
      fill="currentColor"
    />
    <path
      d="M8 19.9842C6.48 19.9842 5.25 18.7542 5.25 17.2442V16.2342C5.25 15.8242 5.59 15.4842 6 15.4842C6.41 15.4842 6.75 15.8242 6.75 16.2342V17.2342C6.75 17.9242 7.31 18.4842 8 18.4842C8.69 18.4842 9.25 17.9242 9.25 17.2442V9.82422C9.25 9.41422 9.59 9.07422 10 9.07422C10.41 9.07422 10.75 9.41422 10.75 9.82422V17.2442C10.75 18.7542 9.52 19.9842 8 19.9842Z"
      fill="currentColor"
    />
    <path
      d="M19 11.7742C18.81 11.7742 18.62 11.7042 18.48 11.5642C17.11 10.2442 14.89 10.2442 13.52 11.5642C13.23 11.8442 12.77 11.8442 12.48 11.5642C11.11 10.2442 8.89 10.2442 7.52 11.5642C7.23 11.8442 6.77 11.8442 6.48 11.5642C5.11 10.2442 2.89 10.2442 1.52 11.5642C1.3 11.7742 0.98 11.8342 0.71 11.7142C0.43 11.5942 0.25 11.3242 0.25 11.0242C0.26 5.6642 4.64 1.3042 10 1.3042C15.36 1.3042 19.74 5.6642 19.75 11.0242C19.75 11.3242 19.57 11.5942 19.29 11.7142C19.2 11.7542 19.1 11.7742 19 11.7742ZM10 9.0842C11.06 9.0842 12.12 9.4042 13 10.0542C14.49 8.9642 16.47 8.7942 18.11 9.5342C17.39 5.7142 14.03 2.8042 10 2.8042C5.97 2.8042 2.61 5.7142 1.89 9.5342C3.53 8.7942 5.51 8.9642 7 10.0542C7.88 9.4042 8.94 9.0842 10 9.0842Z"
      fill="currentColor"
    />
  </svg>
);

export default InsuranceIcon;
