import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import { Translate, useTranslation } from 'hooks/support/useTranslation';
import { useAmplitude } from 'react-amplitude-hooks';
import Avatar from '../avatar/avatar';
import InsuranceIcon from '../icons/insurance-icon';
import PatchIcon from '../icons/patch-icon';
import ContactIcon from '../icons/contact-icon';
import {
  getConsentDetailsRoute,
  getContactDetailsRoute,
  getPersonalDetailsRoute,
  Route,
} from 'utility/routes';
import Link from '../link/link';
import { logout } from 'utility/auth/auth';
import { useRouter } from 'next/router';
import SjfIcon from '../icons/teddy';
import LogoutIcon from '../icons/logout-icon';
import FaqIcon from '../icons/faq-icon';
import { BrandContext } from 'pages/_app';
import { useContext, useState } from 'react';
import { Brands } from 'utility/brands';
import HomeIcon from 'components/icons/home-icon';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { isCustomerCommercial } from 'utility/customerTypes';
import BenefitsIcon from 'components/icons/benefits';
import OffersIcon from 'components/icons/offers-icon';
import ForsiaIcon from 'components/icons/forsia-icon';

interface IListItem {
  text: string;
  key: string;
  path?: Route;
  icon: any;
  color?: string;
  onClick?: (event) => any;
  selected?: boolean;
}

const getGeneralMenuItems = (t: Translate): IListItem[] => [
  {
    text: t('menu.general.items.customerBenefits'),
    key: 'sjfMenuItems-3',
    icon: <BenefitsIcon width={24} height={24} />,
    path: Route.BENEFITS,
  },
  {
    text: t('menu.general.items.FAQ'),
    key: 'sjfMenuItems-1',
    icon: <FaqIcon width={24} height={24} />,
    path: Route.FAQ,
  },
  {
    text: t('menu.general.items.contactUs'),
    key: 'sjfMenuItems-2',
    icon: <ContactIcon width={24} height={24} />,
    path: Route.CONTACT,
  },
];

const getMyPageMenuItems = (t: Translate, brand: string): IListItem[] => {
  let dashboardIcon = <HomeIcon width={24} height={24} />;
  if (brand === Brands.SJF) {
    dashboardIcon = <SjfIcon width={24} height={24} />;
  } else if (brand === Brands.FORSIA) {
    dashboardIcon = <ForsiaIcon width={24} height={24} />;
  }
  return [
    {
      text: t('menu.myPage.items.dashboard'),
      key: 'myPageMenuItems-1',
      icon: dashboardIcon,
      path: Route.DASHBOARD,
    },
    {
      text: t('menu.myPage.items.myInsurances'),
      key: 'myPageMenuItems-2',
      icon: <InsuranceIcon width={24} height={24} />,
      path: Route.MY_INSURANCES,
    },
    {
      text: t('menu.myPage.items.myClaims'),
      key: 'myPageMenuItems-3',
      icon: <PatchIcon width={24} height={24} />,
      path: Route.CLAIMS,
    },
    {
      text: t('menu.myPage.items.buyInsurances'),
      key: 'myPageMenuItems-5',
      icon: <OffersIcon width={24} height={24} />,
      path: Route.BUY_INSURANCES,
    },
  ];
};

const getListItem = ({
  text,
  key,
  icon,
  path,
  onClick,
  selected,
}: IListItem) => {
  if (path) {
    return (
      <Link underline="none" className="u" href={path} key={key}>
        <ListItemButton
          sx={{ p: 0, m: 0, color: 'text.primary' }}
          selected={selected}
        >
          <ListItem>
            <ListItemIcon sx={{ color: selected ? 'primary.main' : '' }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </ListItemButton>
      </Link>
    );
  }
  return (
    <ListItem
      sx={{ color: 'text.primary' }}
      className="u"
      onClick={onClick}
      key={key}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const DrawContent = () => {
  const { t } = useTranslation('common');
  const { data: session } = useSession();
  const { logEvent } = useAmplitude();
  const router = useRouter();
  const brand = useContext(BrandContext);
  const myPageMenuItems = getMyPageMenuItems(
    t,
    brand.details.brandAbbreviation
  );
  const generalMenuItems = getGeneralMenuItems(t);

  const currentPath = router.asPath;
  const [profileDetailsOpen, setProfileDetailsOpen] = useState(
    currentPath.startsWith(Route.PROFILE)
  );

  [...myPageMenuItems, ...generalMenuItems].forEach((item) => {
    if (item.path === '/') {
      item.selected = currentPath === item.path;
    } else {
      item.selected = currentPath.startsWith(item.path);
    }
  });

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          height: 'auto',
          width: 'auto',
          maxWidth: 160,
          maxHeight: 43,
          m: 3,
          mx: 'auto',
          justifyContent: 'center',
        }}
      >
        <Link underline="none" href={Route.DASHBOARD}>
          <img
            style={{ maxHeight: 43, maxWidth: 160 }}
            src={brand.details.media.logo.imageUrl}
            alt="company logo"
          />
        </Link>
      </Box>
      <List
        sx={{
          width: '100vw',
          maxWidth: { xs: 320, md: 270 },
          color: 'text.primary',
          pt: 0,
        }}
      >
        <Link underline="none" href={Route.PROFILE}>
          <ListItemButton
            selected={currentPath === Route.PROFILE}
            sx={{
              m: 0,
              p: 0,
            }}
          >
            <ListItem sx={{ display: { xs: 'flex', md: 'none' } }}>
              <ListItemAvatar>
                <Avatar name={session?.user?.name || ''} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  color:
                    currentPath === Route.PROFILE
                      ? 'primary.main'
                      : 'text.primary',
                }}
                primary={session?.user?.name}
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  px: 2,
                  py: 1,
                  mr: -2,
                  borderRadius: '50%',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setProfileDetailsOpen(!profileDetailsOpen);
                }}
              >
                {profileDetailsOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </ListItem>
          </ListItemButton>
        </Link>
        <Collapse in={profileDetailsOpen} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{ display: { md: 'none' }, pb: 1 }}
          >
            <Link
              className="u"
              underline="none"
              href={getPersonalDetailsRoute()}
            >
              <ListItemButton
                sx={{ pl: 8 }}
                selected={currentPath === getPersonalDetailsRoute()}
              >
                <ListItemText
                  primary={t('profileMenu.personalDetails', true)}
                  primaryTypographyProps={{
                    color:
                      currentPath === getPersonalDetailsRoute()
                        ? 'primary.main'
                        : 'text.primary',
                    variant: 'body2',
                  }}
                />
              </ListItemButton>
            </Link>
            <Link underline="none" href={getContactDetailsRoute()}>
              <ListItemButton
                sx={{ pl: 8 }}
                selected={currentPath === getContactDetailsRoute()}
              >
                <ListItemText
                  primary={t('profileMenu.contactDetails')}
                  primaryTypographyProps={{
                    variant: 'body2',
                    color:
                      currentPath === getContactDetailsRoute()
                        ? 'primary.main'
                        : 'text.primary',
                  }}
                />
              </ListItemButton>
            </Link>
            <Link
              sx={{ display: isCustomerCommercial(session) ? 'none' : 'block' }}
              underline="none"
              href={getConsentDetailsRoute()}
            >
              <ListItemButton
                sx={{ pl: 8 }}
                selected={currentPath === getConsentDetailsRoute()}
              >
                <ListItemText
                  primary={t('profileMenu.consents')}
                  primaryTypographyProps={{
                    variant: 'body2',
                    color:
                      currentPath === getConsentDetailsRoute()
                        ? 'primary.main'
                        : 'text.primary',
                  }}
                />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        {myPageMenuItems.map((item) => getListItem(item))}
        <Divider />
        {generalMenuItems.map((item) => getListItem(item))}
        <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          {getListItem({
            text: t('logout'),
            key: 'logout',
            icon: <LogoutIcon height={24} width={24} />,
            onClick: () =>
              logout(logEvent, brand.details.links.logoutRedirectUrl),
          })}
        </Box>
      </List>
    </>
  );
};

export default DrawContent;
