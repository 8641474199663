import { SetInForceStatus } from './node/wizard/wizard.answers.model';

export enum Route {
  DASHBOARD = '/',
  LOGIN = '/login',
  LOGIN_MITID = '/login/mitid',
  BENEFITS = '/benefits',
  PROFILE = '/profile',
  MY_INSURANCES = '/insurances',
  CONTACT = '/contact',
  CLAIMS = '/claims',
  CLAIM = '/claims/',
  FILE_CLAIM = '/file-claim',
  FILE_CLAIM_SUCCESS = '/file-claim/success/:claimNo',
  FILE_CLAIM_ERROR = '/file-claim/error',
  FAQ = '/faq',
  BUY_INSURANCES = '/buy-insurances',
  BUY_INSURANCES_WIZARD = '/buy-insurances/wizard',
  CHECKOUT = '/basket/checkout',
  CHECKOUT_MANUAL_PROCESS = '/basket/checkout/manual-process',
  CHECKOUT_SUCCESS = '/basket/checkout/success',
  WEBAUTH_SESSION_ID = '/api/auth/webauthn/sessionId',
  WEBAUTH_REGISTER = '/api/auth/webauthn/create',
  WEBAUTH_AUTHENTICATE = '/api/auth/webauthn/authenticate',
  BASKET = '/basket',
  APP = '/app',
}

export const getCheckoutSuccessRoute = (setInForceStatus: SetInForceStatus) =>
  Route.CHECKOUT_SUCCESS + `?setInForceStatus=${setInForceStatus}`;

export const getPersonalDetailsRoute = () =>
  Route.PROFILE + '?section=aboutYou';

export const getContactDetailsRoute = () => Route.PROFILE + '?section=contact';

export const getConsentDetailsRoute = () => Route.PROFILE + '?section=consent';

export const getWebauthDetailsRoute = () => Route.PROFILE + '?section=webauth';

export const getFileClaimSuccessRoute = (claimNo: number) =>
  Route.FILE_CLAIM_SUCCESS.replace(':claimNo', claimNo.toString());

export const getClaimDetailsRoute = (claimCaseNo: number) =>
  `${Route.CLAIM}${claimCaseNo}`;

export const getPolicyDetailsRoute = (policyNo: number) =>
  `${Route.MY_INSURANCES}/${policyNo}`;
