import * as React from 'react';

const LogoutIcon = ({ height, width }: { height: number; width: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71429 2C5.76751 2 5 2.74619 5 3.66667V20.3333C5 21.2538 5.76751 22 6.71429 22H17C17.3156 22 17.5714 21.7513 17.5714 21.4444C17.5714 21.1376 17.3156 20.8889 17 20.8889H6.71429C6.39869 20.8889 6.14286 20.6402 6.14286 20.3333V3.66667C6.14286 3.35984 6.39869 3.11111 6.71429 3.11111H17C17.3156 3.11111 17.5714 2.86238 17.5714 2.55556C17.5714 2.24873 17.3156 2 17 2H6.71429Z"
      fill="currentColor"
    />
    <path
      d="M20.8326 11.0516L17.1961 7.51607C16.9729 7.29912 16.6111 7.29912 16.388 7.51607C16.1648 7.73303 16.1648 8.08479 16.388 8.30175L19.049 10.8889H11.2857C10.9701 10.8889 10.7143 11.1376 10.7143 11.4444C10.7143 11.7513 10.9701 12 11.2857 12H19.049L16.388 14.5871C16.1648 14.8041 16.1648 15.1559 16.388 15.3728C16.6111 15.5898 16.9729 15.5898 17.1961 15.3728L20.8326 11.8373C21.0558 11.6203 21.0558 11.2686 20.8326 11.0516Z"
      fill="currentColor"
    />
  </svg>
);

export default LogoutIcon;
